import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { OwnersPicker, OwnersPickerSchema } from './OwnersPicker/OwnersPicker';
import {
  EntitiesPicker,
  EntitiesPickerSchema,
} from './EntitiesPicker/EntitiesPicker';

import {
  IacPicker,
  IacPickerSchema,
  TagPicker,
  TagPickerSchema,
} from '@internal/plugin-catalog-module-idp';

export const OwnersPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: OwnersPicker,
    name: 'OwnersPicker',
    schema: OwnersPickerSchema,
  }),
);

export const EntitiesPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: EntitiesPicker,
    name: 'EntitiesPicker',
    schema: EntitiesPickerSchema,
  }),
);

export const IacPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: IacPicker,
    name: 'IacPicker',
    schema: IacPickerSchema,
  }),
);

export const TagPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: TagPicker,
    name: 'TagPicker',
    schema: TagPickerSchema,
  }),
);
