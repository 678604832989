import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { OcpAppliancePageApiRef } from './api/OcpAppliancePageApi';
import { OcpAppliancePageClient } from './api/OcpAppliancePageClient';

export const ocpAppliancePagePlugin = createPlugin({
  id: 'ocp-appliance-page',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: OcpAppliancePageApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new OcpAppliancePageClient({ discoveryApi, identityApi }),
    }),
  ],
});

export const OcpAppliancePage = ocpAppliancePagePlugin.provide(
  createRoutableExtension({
    name: 'OcpAppliancePage',
    component: () => import('./components/MainPage').then(m => m.MainPage),
    mountPoint: rootRouteRef,
  }),
);
