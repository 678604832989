import { rootRouteRef } from './routes';

import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { mobileCircleCiApiRef, MobileCircleCiApiClient } from './api';

export const mobileCircleciPlugin = createPlugin({
  id: 'mobile-circleci',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: mobileCircleCiApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new MobileCircleCiApiClient({ discoveryApi, identityApi }),
    }),
  ],
});

export const MobileCircleciPage = mobileCircleciPlugin.provide(
  createRoutableExtension({
    name: 'MobileCircleciPage',
    component: () =>
      import('./components/CircleCIInterface').then(m => m.CircleCIInterface),
    mountPoint: rootRouteRef,
  }),
);
