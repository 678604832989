import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { waitingListApiRef, WaitingListClient } from './api';

export const waitingListPlugin = createPlugin({
  id: 'waiting-list',
  apis: [
    createApiFactory({
      api: waitingListApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new WaitingListClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const WaitingListPage = waitingListPlugin.provide(
  createRoutableExtension({
    name: 'WaitingListPage',
    component: () =>
      import('./components/BucketComponent').then(m => m.BucketComponent),
    mountPoint: rootRouteRef,
  }),
);
