import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'cost-explorer',
});

export const adminRouteRef = createSubRouteRef({
  path: '/cost-explorer/admin',
  id: 'cost-explorer-admin-page',
  parent: rootRouteRef,
});
