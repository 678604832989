import React, { FC } from 'react';
import { Table, TableColumn } from '@backstage/core-components';

import { Link, IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { idpApiRef } from '../../api';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import {
  IdpBaseComponent,
  Task,
  getComponentUniqName,
  normalizeEntityName,
} from '@internal/plugin-catalog-module-common-idp';

type TaskTableProps = {
  tasks: Task[];
  scheduled?: boolean;
};

export const TaskTable: FC<TaskTableProps> = props => {
  const { tasks, scheduled } = props;
  const alertApi = useApi(alertApiRef);
  const idpApi = useApi(idpApiRef);

  async function handleTaskSend(task: Task) {
    await idpApi
      .sendTask(task.component)
      .then(_response => {
        alertApi.post({ message: 'Task was sent', severity: 'info' });
      })
      .catch(e =>
        alertApi.post({ message: `${e.message}`, severity: 'error' }),
      );
  }

  const getLinkToEntity = (row: Task) => {
    let entity_type;
    let name;
    if (row.component === 'service') {
      entity_type = 'component';
    } else if (row.component === 'project') {
      entity_type = 'system';
    } else {
      entity_type = 'resource';
    }

    if (row.component_identifier) {
      if (row.component === 'service' || row.component === 'project') {
        name = normalizeEntityName(row.component_identifier);
      } else {
        name = normalizeEntityName(
          getComponentUniqName({
            _id: row.component_id,
            component: row.component,
            identifier: row.component_identifier,
          } as IdpBaseComponent),
        );
      }

      return (
        <Link href={`/catalog/default/${entity_type}/${name}`}>
          {`${name} (${row.component})`}
        </Link>
      );
    }
    return (
      <Link
        href={`/catalog?filters%5Bkind%5D=${entity_type}&filters%5Buser%5D=all`}
      >
        {row.component}
      </Link>
    );
  };

  const columns: TableColumn[] = [
    {
      title: 'Component',
      field: 'component',
      customFilterAndSearch: (filter, row: any) =>
        row.component
          .toLocaleUpperCase('en-US')
          .includes(filter.toLocaleUpperCase('en-US')),
      render: (row: any) => {
        return <>{row.scheduled ? row.component : getLinkToEntity(row)}</>;
      },
    },
    {
      title: 'Status',
      field: 'status',
    },
    { title: 'Updated at', field: 'updated_at' },
    { title: 'Requester', field: 'requester' },
    {
      title: 'Actions',
      render: (row: any) => {
        return (
          <>
            {row.scheduled && (
              <IconButton
                title="Execute task"
                onClick={() => handleTaskSend(row)}
              >
                <RefreshIcon fontSize="small" />
              </IconButton>
            )}
          </>
        );
      },
    },
  ];

  const data = tasks.map((task: Task) => {
    return {
      component: task.component,
      component_id: task.component_id,
      status: task.status,
      updated_at: task.updated_at
        ? new Date(task.updated_at).toISOString()
        : 'unknown',
      requester: task.requester,
      scheduled: task.scheduled,
      component_identifier: task.component_identifier,
    };
  });

  const showPagination = data.length > 20;

  return (
    <Table
      title={scheduled ? 'Scheduled' : 'Regular'}
      options={{
        paging: showPagination,
        pageSize: 20,
        actionsColumnIndex: -1,
        loadingType: 'linear',
        padding: 'dense',
        pageSizeOptions: [20, 50, 100],
      }}
      columns={columns}
      data={data}
    />
  );
};
