import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const AppRegistrationIcon = () => {
  return (
    <SvgIcon>
      <rect height="4" width="4" x="10" y="4" />
      <rect height="4" width="4" x="4" y="16" />
      <rect height="4" width="4" x="4" y="10" />
      <rect height="4" width="4" x="4" y="4" />
      <polygon points="14,12.42 14,10 10,10 10,14 12.42,14" />
      <path d="M20.88,11.29l-1.17-1.17c-0.16-0.16-0.42-0.16-0.58,0L18.25,11L20,12.75l0.88-0.88C21.04,11.71,21.04,11.45,20.88,11.29z" />
      <polygon points="11,18.25 11,20 12.75,20 19.42,13.33 17.67,11.58" />
      <rect height="4" width="4" x="16" y="4" />
    </SvgIcon>
  );
};
