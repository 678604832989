import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { CfRouterApi } from './CfRouterApi';

export class CfRouterApiClient implements CfRouterApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async get(path: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/plugins-cf-router${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      return { payload, message };
    }

    return await response.json();
  }

  async download(path: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/plugins-cf-router${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    return await response.text();
  }

  async downloadCapabilities(applianceId: string, env: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/ocp-user-page/${env}/api/v2/appliances/pnc/${applianceId}/capabilities`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    return await response.json();
  }
}
