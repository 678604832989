import { createApiRef } from '@backstage/core-plugin-api';

/** @public */
export const HaclEventsCountPageApiRef = createApiRef<HaclEventsCountPageApi>({
  id: 'plugin.hacl-events-count.service',
});

/** @public */
export interface HaclEventsCountPageApi {
  get(path: string): Promise<any>;
  getHaclEvents(filters: object): any;
}
