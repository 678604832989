import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { CnsPhoenixServiceApi, cnsPhoenixServiceApiRef } from './api/common';
import { rootRouteRef } from './routes';

export const cnsPlugin = createPlugin({
  id: 'cns',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: cnsPhoenixServiceApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) => new CnsPhoenixServiceApi({ discoveryApi, identityApi }),
    }),
  ],
});

export const CnsPage = cnsPlugin.provide(
  createRoutableExtension({
    name: 'CnsPage',
    component: () => import('./pages/CnsPage').then((m) => m.CnsPage),
    mountPoint: rootRouteRef,
  }),
);
