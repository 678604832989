import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { StatusPageApiClient, statusPageApiRef } from './api';

export const StatusPagePlugin = createPlugin({
  id: 'status-page',
  apis: [
    createApiFactory({
      api: statusPageApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new StatusPageApiClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const StatusPage = StatusPagePlugin.provide(
  createRoutableExtension({
    name: 'StatusPage',
    component: () => import('./components/Root').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
