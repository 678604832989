import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { AuditClient, auditApiRef } from './api';

export const auditsPlugin = createPlugin({
  id: 'audits',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: auditApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new AuditClient({ discoveryApi, fetchApi }),
    }),
  ],
});

export const AuditsPage = auditsPlugin.provide(
  createRoutableExtension({
    name: 'AuditsPage',
    component: () =>
      import('./components/AuditComponent').then(m => m.AuditComponent),
    mountPoint: rootRouteRef,
  }),
);

export const EntityAuditListCard = auditsPlugin.provide(
  createComponentExtension({
    name: 'EntityAuditListCard',
    component: {
      lazy: () => import('./components/AuditWidget').then(m => m.AuditWidget),
    },
  }),
);
