import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { cfRouterApiRef, CfRouterApiClient } from './api';

import { rootRouteRef } from './routes';

export const ConfigFilesPlugin = createPlugin({
  id: 'config-files',
  apis: [
    createApiFactory({
      api: cfRouterApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new CfRouterApiClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const ConfigFilesPage = ConfigFilesPlugin.provide(
  createRoutableExtension({
    name: 'ConfigFilesPage',
    component: () =>
      import('./components/CFComponent').then(m => m.CFComponent),
    mountPoint: rootRouteRef,
  }),
);
