import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const MongoDBLogoIcon = () => {
  return (
    <SvgIcon viewBox="3 0 24 24">
      <path d="M 11.867188 17.390625 C 11.867188 17.390625 11.867188 9.617188 12.125 9.617188 C 12.324219 9.617188 12.582031 19.640625 12.582031 19.640625 C 12.226562 19.601562 11.867188 17.992188 11.867188 17.390625 Z M 16.867188 9.707031 C 16.175781 6.695312 14.667969 4.105469 12.585938 2.109375 L 12.582031 2.105469 C 12.328125 1.714844 12.09375 1.273438 11.910156 0.804688 L 11.890625 0.753906 C 11.890625 0.769531 11.890625 0.785156 11.890625 0.804688 C 11.890625 1.226562 11.703125 1.605469 11.402344 1.863281 C 8.71875 4.136719 7.015625 7.492188 6.957031 11.242188 L 6.957031 11.253906 C 6.957031 11.300781 6.957031 11.355469 6.957031 11.410156 C 6.957031 15.128906 8.746094 18.425781 11.511719 20.496094 L 11.539062 20.515625 L 11.605469 20.5625 C 11.726562 21.457031 11.828125 22.351562 11.914062 23.246094 L 12.363281 23.246094 C 12.488281 22.128906 12.65625 21.148438 12.875 20.1875 L 12.84375 20.367188 C 13.140625 20.164062 13.398438 19.949219 13.640625 19.714844 L 13.640625 19.71875 C 15.738281 17.769531 17.050781 14.996094 17.050781 11.914062 C 17.050781 11.871094 17.050781 11.824219 17.050781 11.78125 L 17.050781 11.785156 C 17.046875 11.050781 16.980469 10.332031 16.855469 9.632812 Z M 16.867188 9.707031 " />
    </SvgIcon>
  );
};
