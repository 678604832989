import React from 'react';

import { FormControl, Typography } from '@material-ui/core';
import useEffectOnce from 'react-use/lib/useEffectOnce';

import { Content, ContentHeader, InfoCard } from '@backstage/core-components';

import KeyValue from './KeyValueComponent';
import { TagPickerProps } from './schema';

export interface ObjectComponentProps {
  label: string;
  field: string;
  formik: any;
}

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
);

type Values = {
  keyItem: string;
  valueItem: string;
};

export function TagPicker(props: TagPickerProps) {
  const classes = useStyles();
  const { formData, onChange } = props;

  function convertValues(values: Record<string, string> | undefined) {
    if (!values) {
      return [];
    }

    const newValueList = [];
    for (const i in values) {
      if (i) {
        const v = values[i];
        newValueList.push({ keyItem: i, valueItem: v });
      }
    }
    return newValueList;
  }

  type NewTag = {
    keyItem: string;
    valueItem: string;
  };

  function convertValuesToDict(values: NewTag[]) {
    const newValueList: Record<string, string> = {};
    for (let i = 0; i < values.length; i++) {
      const v = values[i];
      newValueList[v.keyItem] = v.valueItem;
    }
    return newValueList;
  }

  const handleAddNew = (v: Values[]) => {
    onChange(convertValuesToDict(v));
  };

  useEffectOnce(() => onChange(formData || {}));

  return (
    <FormControl className={classes.formControl}>
      <Content>
        <ContentHeader titleComponent={<Typography>Tag</Typography>} />
        <InfoCard>
          <KeyValue
            rows={convertValues(formData)}
            onChange={handleAddNew}
            blacklistedKeys={[
              'host',
              'device',
              'source',
              'service',
              'env',
              'version',
            ]}
          />
        </InfoCard>
      </Content>
    </FormControl>
  );
}
