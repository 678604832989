import { CostExplorerApi } from './CostExplorerApi';
import { Report } from '../components/types';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { Config } from '@backstage/config';
import {
  CostAnalysisResponse,
  CostFormulaReportResponse,
  CostFormulasResponse,
  CostItemsResponse,
  CostModelsResponse,
  CostReportsResponse,
  InvoiceAnalyzerViewResponse,
  UpdateReportResponse,
  CurrencyConversionResponse,
} from './types';
import Freecurrencyapi from '@everapi/freecurrencyapi-js';

/** @public */

export class CostExplorerClient implements CostExplorerApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async getAllReports(
    category: string,
    merge?: string,
  ): Promise<CostReportsResponse> {
    const url = `/cost-explorer/reports?category=${category}&merge=${merge}`;
    return await this.get(url);
  }

  async searchReports(
    category: string,
    full_name: string,
  ): Promise<CostReportsResponse> {
    const url = `/cost-explorer/search_reports?category=${category}&full_name=${full_name}`;
    return await this.get(url);
  }

  async updateCostReport(report: Report): Promise<UpdateReportResponse> {
    let url = '/cost-explorer/report';
    if (report._id !== '') {
      url += `/${report._id}`;
    }
    return await this.put(url, report);
  }

  async deleteCostReport(report: Report): Promise<UpdateReportResponse> {
    return await this.delete(`/cost-explorer/report/${report._id}`, report);
  }

  async getAllFormulas(): Promise<CostFormulasResponse> {
    const url = '/cost-explorer/formula';
    return await this.get(url);
  }

  async getFormulaReports(
    formulaId: string,
  ): Promise<CostFormulaReportResponse> {
    const url = `/cost-explorer/formula/${formulaId}`;
    return await this.get(url);
  }

  async analyzeMongoAtlasCosts(
    filters: string,
    groupBy: string,
    granularity: string,
    past: string,
  ): Promise<CostAnalysisResponse> {
    const url = `/cost-explorer/mongo-atlas-analyzer?filters=${filters}&group_by=${groupBy}&granularity=${granularity}&past=${past}`;
    return await this.get(url);
  }

  async getCostItems(params: {
    vendor: string;
    view: string;
    filters: string;
    groupBy: string;
    display_columns?: string;
    granularity: string;
    start_at: Date;
    end_at: Date;
  }): Promise<CostItemsResponse> {
    const {
      vendor,
      view,
      filters,
      groupBy,
      display_columns,
      granularity,
      start_at,
      end_at,
    } = params;
    let url = `/cost-explorer/cost-items?vendor=${vendor}&view=${view}&filters=${filters}&group_by=${groupBy}&granularity=${granularity}&start_at=${start_at.getTime()}&end_at=${end_at.getTime()}`;
    if (display_columns) {
      url += `&display_columns=${display_columns}`;
    }

    return await this.get(url, 'plugins-ce-backend');
  }

  async getCostModel(name: string): Promise<CostModelsResponse> {
    const url = `/cost-explorer/cost-model/${name}`;
    return await this.get(url, 'plugins-ce-backend');
  }

  async getInvoiceAnalyzerView(
    vendor: string,
  ): Promise<InvoiceAnalyzerViewResponse> {
    const url = `/cost-explorer/invoice-analyzer-view/${vendor}`;
    return await this.get(url);
  }

  async get(path: string, backend?: string): Promise<any> {
    const bk = backend ? backend : 'phoenix-idp-backend';
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}/${bk}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }

    return await response.json();
  }

  async post(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/phoenix-idp-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'POST',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const response_text = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${response_text}`;
      throw new Error(message);
    }

    return await response.json();
  }

  async put(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/phoenix-idp-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'PUT',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const response_text = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${response_text}`;
      throw new Error(message);
    }

    return await response.json();
  }

  async delete(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/phoenix-idp-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'DELETE',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const response_text = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${response_text}`;
      throw new Error(message);
    }

    return await response.json();
  }

  async getCurrencyConversions(
    config: Config,
  ): Promise<CurrencyConversionResponse> {
    const apikey = config.getOptionalString('auth.freecurrency');
    const freecurrencyapi = new Freecurrencyapi(apikey);
    const response = await freecurrencyapi.latest({
      base_currency: 'USD',
      currencies: ['USD', 'EUR', 'SEK'],
    });
    if (!('data' in response)) {
      const response_text = await response.message;
      const message = `Request failed with: ${response_text}`;
      throw new Error(message);
    }
    return response;
  }
}
