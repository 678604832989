import { z } from 'zod';

export const INVALID_INPUT = 'INVALID';

const deliverableStatusTypes = [
  'Not started',
  'Released',
  'Work in progress',
  INVALID_INPUT,
] as const;
export const deliverableStatusSchema = z.enum(deliverableStatusTypes);

const checkpointTypes = [
  'CPPF',
  'CP00',
  'CP0',
  'CP1',
  'CP2',
  'CP3',
  INVALID_INPUT,
] as const;
export const checkpointSchema = z.enum(checkpointTypes);

const checkpointActionTypes = [
  'Create',
  'Update',
  'Final',
  'Follow up',
  'Make',
  INVALID_INPUT,
] as const;
export const checkpointActionSchema = z.enum(checkpointActionTypes);

const consumerExperienceTypes = [
  'Taste',
  'Care',
  'Wellbeing',
  INVALID_INPUT,
] as const;
export const consumerExperienceSchema = z.enum(consumerExperienceTypes);

const productSubCategoryTypes = [
  'Food Preparation',
  'Food Preservation',
  'Fabric Care',
  'Dish Care',
  'Floor Care',
  'Air Care',
  INVALID_INPUT,
] as const;
export const productSubCategorySchema = z.enum(productSubCategoryTypes);

// Allowing multiple BAs, split by the sign '&'
const businessAreaTypes = [
  'NA',
  'EU',
  'LATAM',
  'APAC',
  'MEA',
  'APMEA',
  INVALID_INPUT,
];
export const businessAreaSchema = z
  .array(z.string())
  .refine((businessAreas: string[]) => {
    return !businessAreas
      .map((businessArea: string) => {
        return businessAreaTypes.includes(businessArea);
      })
      .includes(false);
  });

// Allowing multiple brands, split by the sign '&'
const brandTypes = [
  'electrolux',
  'frigidaire',
  'aeg',
  'westinghouse',
  INVALID_INPUT.toLocaleLowerCase('en-US'),
];
export const brandSchema = z.array(z.string()).refine((brands: string[]) => {
  return !brands
    .map((brand: string) => {
      return brandTypes.includes(brand.toLocaleLowerCase('en-US'));
    })
    .includes(false);
});

export const pdclProjectSchema = z.object({
  id: z.string(),
  name: z.string(),
  consumerExperience: consumerExperienceSchema,
  productSubCategory: productSubCategorySchema,
  businessArea: businessAreaSchema,
  brand: brandSchema,
  url: z.string(),
});

export const pdclDeliverableSchema = z
  .object({
    key: z.string(),
    project: pdclProjectSchema,
    checkpoint: checkpointSchema,
    deliverable: z.string().refine(
      (deliverable: string) => {
        return deliverable.includes('[') && deliverable.includes(']');
      },
      { message: 'Invalid deliverable name' },
    ),
    action: checkpointActionSchema,
    responsible: z.string(),
    status: deliverableStatusSchema,
    version: z.string(),
    url: z.string(),
  })
  .refine(
    object => {
      return (
        object.project.name === object.deliverable.split(']')[0].substring(1)
      );
    },
    { message: 'Deliverable name and project name do not match!' },
  );
