import React, { useState, useCallback } from 'react';
import { TaskTable } from './TaskTable';
import { idpApiRef } from '../../api';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { Task } from '@internal/plugin-catalog-module-common-idp';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import useInterval from 'react-use/lib/useInterval';

export function TaskComponent(props: { scheduled?: boolean }) {
  const { scheduled } = props;
  const idpApi = useApi(idpApiRef);
  const alertApi = useApi(alertApiRef);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [counter, setCounter] = useState<number>(0);

  const getTasks = useCallback(async () => {
    await idpApi
      .getTasks(scheduled)
      .then(response => {
        setTasks(response.tasks);
      })
      .catch(e =>
        alertApi.post({ message: `${e.message}`, severity: 'error' }),
      );
  }, [alertApi, idpApi, scheduled]);

  useEffectOnce(() => {
    getTasks();
  });

  useInterval(() => {
    if (counter < 30) {
      setCounter(counter + 1);
      getTasks();
    }
  }, 5000);

  return <>{tasks && <TaskTable tasks={tasks} scheduled={scheduled} />}</>;
}

export default TaskComponent;
