import { rootRouteRef } from './routes';
import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { VpnClient, vpnApiRef } from './api';

export const vpnPlugin = createPlugin({
  id: 'vpn',
  apis: [
    createApiFactory({
      api: vpnApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new VpnClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const VpnPage = vpnPlugin.provide(
  createRoutableExtension({
    name: 'VpnPage',
    component: () =>
      import('./components/VpnComponent').then(m => m.VpnComponent),
    mountPoint: rootRouteRef,
  }),
);

export const VpnAdminPage = vpnPlugin.provide(
  createRoutableExtension({
    name: 'VpnAdminPage',
    component: () =>
      import('./components/VpnAdminComponent').then(m => m.VpnAdminComponent),
    mountPoint: rootRouteRef,
  }),
);
