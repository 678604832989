import { createApiRef } from '@backstage/core-plugin-api';

export type StatusPageApi = {
  getRegions(): Promise<any>;
  getEnvironments(): Promise<any>;
  getDatadogMetrics(query: string): Promise<any>;
};

export const statusPageApiRef = createApiRef<StatusPageApi>({
  id: 'plugin.statuspage.service',
});
