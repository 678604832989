import { z } from 'zod';
import { makeFieldSchemaFromZod } from '@backstage/plugin-scaffolder';

/**
 * @public
 */
export const EntitiesPickerFieldSchema = makeFieldSchemaFromZod(
  z.array(z.string()),
  z.object({
    kinds: z
      .array(z.string())
      .optional()
      .describe('List of kinds of entities to derive tags from'),
    resource_type: z
      .string()
      .optional()
      .describe('Type of resource or component'),
    helperText: z.string().optional().describe('Helper text to display'),
  }),
);

export const EntitiesPickerSchema = EntitiesPickerFieldSchema.schema;

export type EntitiesPickerProps = typeof EntitiesPickerFieldSchema.type;

/**
 * The input props that can be specified under `ui:options` for the
 * `EntitiesPicker` field extension.
 *
 * @public
 */
export type EntitiesPickerUiOptions =
  typeof EntitiesPickerFieldSchema.uiOptionsType;
