import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { OwnersPickerFieldExtension } from './scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';

import { OcpAppliancePagePage } from '@internal/plugin-ocp-appliance-page';
import { CcpaPage } from '@electrolux/plugin-ccpa';
import { ApplianceRegistrationPage } from '@electrolux/plugin-appliance-registration';
import { CostExplorerPage } from '@electrolux/plugin-cost-explorer';
import { MobileCircleciPage } from '@electrolux/plugin-mobile-circleci';
import { ConfigFilesPage } from '@internal/plugin-config-files';
import { UserPage } from '@internal/plugin-user-page';
import { AppliancesPage } from '@internal/plugin-appliance-page';
import { StatusPage } from '@internal/plugin-status-page';
import { CnsPage } from '@internal/plugin-cns';

import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import {
  CatalogGraphPage,
  catalogGraphPlugin,
} from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { SignInPage, SignInProviderConfig } from '@backstage/core-components';

import type { IdentityApi } from '@backstage/core-plugin-api';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { homePage } from './components/home/HomePage';
import {
  EntitiesPickerFieldExtension,
  IacPickerFieldExtension,
  TagPickerFieldExtension,
} from './scaffolder/extensions';
import { TasksList } from '@internal/plugin-catalog-module-idp';
import { VpnPage, VpnAdminPage } from '@internal/plugin-vpn';
import { ServiceCatalogPage } from '@internal/plugin-service-catalog';
import { QetaPage } from '@drodil/backstage-plugin-qeta';
import { AuditsPage } from '@internal/plugin-audits';
import { HaclEventsCountPage } from '@internal/plugin-hacl-events-count';
import { SlackPage } from '@internal/plugin-slack';
import { AdpPage } from '@internal/plugin-adp';
import { WaitingListPage } from '@internal/plugin-waiting-list';
import { LabmatePage } from '@internal/plugin-labmate';
import {
  OrgChartPage,
  OrgTeamsPage,
} from '@internal/backstage-plugin-org-chart';
import { InfraWalletPage } from '@electrolux-oss/plugin-infrawallet';
import { InfraPurePage } from '@electrolux-internal/backstage-plugin-infrapure';

const microsoftAuthProvider: SignInProviderConfig = {
  id: 'azure-auth-provider',
  title: 'Microsoft Active Directory',
  message: 'Sign in to Phoenix using your Active Directory account.',
  apiRef: microsoftAuthApiRef,
};

const app = createApp({
  apis,
  components: {
    SignInPage: props => {
      return (
        <SignInPage
          {...props}
          auto
          provider={microsoftAuthProvider}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(catalogGraphPlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog-page" />} />
    <Route path="/home" element={<HomepageCompositionRoot />}>
      {homePage}
    </Route>
    <Route path="/status-page" element={<StatusPage />} />
    <Route path="/config-files" element={<ConfigFilesPage />} />
    <Route
      path="/user-page/:env/:region/:username/:brand"
      element={<UserPage />}
    />
    <Route path="/user-page" element={<UserPage />} />
    <Route
      path="/appliances/:env/:region/:applianceId"
      element={<AppliancesPage />}
    />
    <Route path="/appliances" element={<AppliancesPage />} />
    <Route path="/catalog-page" element={<ServiceCatalogPage />} />
    <Route
      path="/ocp-appliance-page/:env/:region/:applianceId"
      element={<OcpAppliancePagePage />}
    />
    <Route path="/ocp-appliance-page" element={<OcpAppliancePagePage />} />
    <Route path="/ccpa" element={<CcpaPage />} />
    <Route path="/cns" element={<CnsPage />} />
    <Route
      path="/appliance-registration"
      element={<ApplianceRegistrationPage />}
    />
    <Route path="/cost-explorer" element={<CostExplorerPage />} />
    <Route path="/mobile-circleci" element={<MobileCircleciPage />} />

    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/catalog/tasks" element={<TasksList />}>
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <OwnersPickerFieldExtension />
        <EntitiesPickerFieldExtension />
        <IacPickerFieldExtension />
        <TagPickerFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/vpn" element={<VpnPage />} />
    <Route path="/vpn/admin" element={<VpnAdminPage />} />
    <Route path="/vpn/:user" element={<VpnPage />} />
    <Route path="/qeta" element={<QetaPage title="Questions" />} />
    <Route path="/audits" element={<AuditsPage />} />
    <Route path="/hacl-events-count" element={<HaclEventsCountPage />} />
    <Route path="/slack" element={<SlackPage />} />
    <Route path="/adp" element={<AdpPage />} />
    <Route path="/waiting-list" element={<WaitingListPage />} />
    <Route path="/waiting-list/:bucket_id" element={<WaitingListPage />} />
    <Route path="/labmate" element={<LabmatePage />} />
    <Route path="/org-chart" element={<OrgChartPage />} />
    <Route path="/infrawallet" element={<InfraWalletPage />} />
    <Route path="/infrapure" element={<InfraPurePage />} />
    <Route path="/org-teams" element={<OrgTeamsPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay transientTimeoutMs={2500} />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
