import { UserPageApi } from './UserPageApi';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { UsersResponse, AppliancesResponse, CDCUserResponse } from './types';

export class UserPageClient implements UserPageApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async getUsers(url: string): Promise<UsersResponse> {
    const response = await this.get(url);
    return {
      users: response.body,
      status: response.status,
    };
  }

  async getAppliances(url: string): Promise<AppliancesResponse> {
    const response = await this.get(url);
    return {
      appliances: response.body,
      status: response.status,
    };
  }

  async getCDCUser(url: string): Promise<CDCUserResponse> {
    const response = await this.get(url);
    return {
      user: response.body,
      status: response.status,
    };
  }

  async getDeltaAppliances(url: string): Promise<any> {
    const response = await this.get(url);
    return {
      appliances: response.body,
      status: response.status,
    };
  }

  async get(path: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }
    const data = await response.json();
    const status = response.status;
    return {
      body: data,
      status,
    };
  }
}
