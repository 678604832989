import { createApiRef } from '@backstage/core-plugin-api';
import { Report } from '../components/types';
import {
  CostAnalysisResponse,
  CostFormulaReportResponse,
  CostFormulasResponse,
  CostItemsResponse,
  CostModelsResponse,
  CostReportsResponse,
  InvoiceAnalyzerViewResponse,
  UpdateReportResponse,
  CurrencyConversionResponse,
} from './types';
import { Config } from '@backstage/config';

/** @public */
export const costExplorerApiRef = createApiRef<CostExplorerApi>({
  id: 'plugin.cost-explorer.service',
});

/** @public */
export interface CostExplorerApi {
  get(path: string): Promise<any>;
  post(path: string, payload: { [key: string]: any }): Promise<any>;
  put(path: string, payload: { [key: string]: any }): Promise<any>;
  delete(path: string, payload: { [key: string]: any }): Promise<any>;
  getAllReports(category: string, merge?: string): Promise<CostReportsResponse>;
  searchReports(
    category: string,
    full_name: string,
  ): Promise<CostReportsResponse>;
  updateCostReport(report: Report): Promise<UpdateReportResponse>;
  deleteCostReport(report: Report): Promise<UpdateReportResponse>;
  getAllFormulas(): Promise<CostFormulasResponse>;
  getFormulaReports(formulaId: string): Promise<CostFormulaReportResponse>;
  analyzeMongoAtlasCosts(
    filters: string,
    groupBy: string,
    granularity: string,
    past: string,
  ): Promise<CostAnalysisResponse>;
  getInvoiceAnalyzerView(vendor: string): Promise<InvoiceAnalyzerViewResponse>;
  getCostItems(params: {
    vendor: string;
    view: string;
    filters: string;
    groupBy: string;
    display_columns?: string;
    granularity: string;
    start_at: Date;
    end_at: Date;
  }): Promise<CostItemsResponse>;
  getCostModel(name: string): Promise<CostModelsResponse>;
  getCurrencyConversions(config: Config): Promise<CurrencyConversionResponse>;
}
