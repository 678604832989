import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
  configApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { AdpApiClient, adpApiRef } from './api/adpApiClient';
import {
  AdpPhoenixServiceApi,
  adpPhoenixServiceApiRef,
} from './api-lambda/adp.api';

export const adpPlugin = createPlugin({
  id: 'adp',
  apis: [
    createApiFactory({
      api: adpApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) => new AdpApiClient({ discoveryApi }),
    }),
    createApiFactory({
      api: adpPhoenixServiceApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
        configApi: configApiRef,
      },
      factory: ({ discoveryApi, identityApi, configApi }) =>
        new AdpPhoenixServiceApi({ discoveryApi, identityApi, configApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const AdpPage = adpPlugin.provide(
  createRoutableExtension({
    name: 'AdpPage',
    component: () => import('./components/Root').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
