import { ENTITY_STATUS, IDP_ORGANIZATION_ID_ANNOTATION } from './constants';
import { IdpBaseComponent } from './types';

import { Entity } from '@backstage/catalog-model';
import {
  IDP_UI_TABS_ANNOTATION,
  IDP_RESOURCE_ID_ANNOTATION,
  IDP_COMPONENT_ID_ANNOTATION,
  IDP_PROJECT_ID_ANNOTATION,
} from './constants';

export const getColorStatus = (status: ENTITY_STATUS) => {
  if (
    [ENTITY_STATUS.PROVISIONED, ENTITY_STATUS.AUTO_DISCOVERED, 200].includes(
      status,
    )
  ) {
    return 'OK';
  }

  if (
    [
      ENTITY_STATUS.PENDING_FOR_APPROVAL,
      ENTITY_STATUS.PENDING_FOR_APPROVAL_OF_DESTROY,
      ENTITY_STATUS.PENDING_FOR_APPROVAL_OF_UPDATE,
      ENTITY_STATUS.PROVISIONING_QUEUED,
      ENTITY_STATUS.UPDATE,
      ENTITY_STATUS.DESTROY_QUEUED,
      ENTITY_STATUS.DRY_RUN_QUEUED,
      ENTITY_STATUS.PROVISIONING_APPROVED,
      ENTITY_STATUS.DESTROY_APPROVED,
      ENTITY_STATUS.UPDATE_AUTO_PROMOTED,
      ENTITY_STATUS.DESTROY_AUTO_PROMOTED,
      ENTITY_STATUS.PROVISIONING_AUTO_PROMOTED,
      ENTITY_STATUS.BLUEPRINT_DEPENDENCY,
    ].includes(status)
  ) {
    return 'PENDING';
  }

  if ([ENTITY_STATUS.DECLINED, ENTITY_STATUS.DESTROYED].includes(status)) {
    return 'ABORTED';
  }

  if (
    [
      ENTITY_STATUS.PROVISIONING_IN_PROGRESS,
      ENTITY_STATUS.UPDATE_IN_PROGRESS,
      ENTITY_STATUS.DESTROY_IN_PROGRESS,
      ENTITY_STATUS.DRY_RUN_IN_PROGRESS,
    ].includes(status)
  ) {
    return 'RUNNING';
  }

  return 'ERROR';
};

export const canEdit = (entity: any) => {
  if (entity.is_owner || entity.can_modify) {
    return [
      ENTITY_STATUS.PENDING_FOR_APPROVAL,
      ENTITY_STATUS.PROVISIONED,
      ENTITY_STATUS.ERROR,
      ENTITY_STATUS.UPDATE_ERROR,
      ENTITY_STATUS.AUTO_DISCOVERED,
    ].includes(entity.status);
  }

  return false;
};

export const canReprovision = (entity: any) => {
  if (entity.is_owner || entity.can_modify) {
    return [
      ENTITY_STATUS.ERROR,
      ENTITY_STATUS.UPDATE_ERROR,
      ENTITY_STATUS.PLAN_ERROR,
      ENTITY_STATUS.DESTROY_ERROR,
      ENTITY_STATUS.PROVISIONED,
      ENTITY_STATUS.DESTROYED,
      ENTITY_STATUS.AUTO_DISCOVERED,
    ].includes(entity.status);
  }

  return false;
};

export const canApprove = (entity: any) => {
  if ((entity.is_owner || entity.is_admin) && entity.can_modify) {
    return [
      ENTITY_STATUS.PENDING_FOR_APPROVAL,
      ENTITY_STATUS.PENDING_FOR_APPROVAL_OF_DESTROY,
    ].includes(entity.status);
  }

  return false;
};

export const canDestroy = (entity: any) => {
  if ((entity.is_owner || entity.is_admin) && entity.can_modify) {
    return [
      ENTITY_STATUS.PROVISIONED,
      ENTITY_STATUS.UPDATE_ERROR,
      ENTITY_STATUS.ERROR,
      ENTITY_STATUS.AUTO_DISCOVERED,
    ].includes(entity.status);
  }

  return false;
};

export const canDelete = (entity: any) => {
  if (entity.is_owner || entity.can_modify) {
    return [
      ENTITY_STATUS.DESTROYED,
      ENTITY_STATUS.PENDING_FOR_APPROVAL,
      ENTITY_STATUS.DECLINED,
    ].includes(entity.status);
  }

  return false;
};

export const canDiscovery = (entity: any) => {
  if (entity.is_owner || entity.is_admin) {
    return ![
      ENTITY_STATUS.DESTROYED,
      ENTITY_STATUS.PENDING_FOR_APPROVAL,
      ENTITY_STATUS.DECLINED,
      ENTITY_STATUS.BLUEPRINT_DEPENDENCY,
    ].includes(entity.status);
  }

  return false;
};

export function normalizeEntityName(name: string): string {
  if (!name) {
    return 'undefined';
  }
  let cleaned = name
    .trim()
    .toLocaleLowerCase()
    .replace(/[^a-zA-Z0-9_\-\.]/g, '_');

  // invalid to end with _
  while (cleaned.endsWith('_')) {
    cleaned = cleaned.substring(0, cleaned.length - 1);
  }

  // invalid to start with _
  while (cleaned.startsWith('_')) {
    cleaned = cleaned.substring(1);
  }

  // cleans up format for groups like 'my group (Reader)'
  while (cleaned.includes('__')) {
    // replaceAll from node.js >= 15
    cleaned = cleaned.replace('__', '_');
  }

  const shorted = cleaned.substring(0, 63);
  if (shorted.endsWith('-') || shorted.endsWith('_')) {
    return shorted.substring(0, 62);
  }

  return shorted;
}

export function getComponentName(resource: any) {
  return resource.environmental_identifier || resource.identifier || 'unknown';
}

export function getComponentUniqName(resource: any) {
  const identifier = getComponentName(resource);
  const id = resource._id;

  const shorted = identifier.substring(0, 59);
  if (shorted.endsWith('-') || shorted.endsWith('_')) {
    return `${shorted.substring(0, 58)}-${id.slice(-3)}`;
  }
  return `${shorted}-${id.slice(-3)}`;
}

export function tagsToString(tags: Record<string, string> | undefined) {
  const resultList: string[] = [];
  if (!tags) return [];
  for (const key in tags) {
    if (key) {
      resultList.push(
        `${key}:${tags[key]}`.toLocaleLowerCase().replaceAll(/\W/g, ''),
      );
    }
  }
  return resultList;
}

export function extractEntityDataFromComponent(resource: IdpBaseComponent) {
  let owner: string = 'sre';
  let identifier: string;
  let lifecycle: string;
  let region: string;
  let tags: string[];
  const componentType: string = resource.component;
  const dependsOn: string[] = [];
  const links: any = [];

  switch (resource.component) {
    case 'executor': {
      region = resource.environment_component?.region as string;
      lifecycle = `${resource.environment_component?.environment_name}/${region}`;
      identifier = resource.identifier as string;
      owner = normalizeEntityName(
        resource.environment_component?.project?.identifier as string,
      );
      dependsOn.push(
        `resource:${normalizeEntityName(
          getComponentUniqName(resource.service),
        )}`,
      );
      tags = tagsToString(resource.tags);
      break;
    }
    default: {
      if (resource.aws_account || resource.resource === 'aws_account') {
        // non-region components
        region = resource.environment_component?.region || ('global' as string);
        lifecycle = `${resource?.aws_account?.environment_name}/${region}`;
        owner = normalizeEntityName(resource?.project?.identifier as string);
        dependsOn.push(
          `resource:${normalizeEntityName(
            getComponentUniqName(resource.organization),
          )}`,
        );
      } else if (
        resource.atlas_account ||
        resource.resource === 'atlas_account'
      ) {
        // non-region components
        region = resource.environment_component?.region || ('global' as string);
        // lifecycle = `${resource?.atlas_account?.environment_name}/${region}`;
        lifecycle = 'unknown';
        owner = normalizeEntityName(
          resource?.organization?.identifier as string,
        );
        dependsOn.push(
          `resource:${normalizeEntityName(
            getComponentUniqName(resource?.organization),
          )}`,
        );
      } else {
        region = resource.environment_component?.region as string;
        lifecycle = `${resource.environment_component?.environment_name}/${region}`;
        owner = normalizeEntityName(resource.project?.identifier as string);
      }

      identifier = resource.name || resource.identifier || 'unknown';
      tags = tagsToString(resource.tags);
      break;
    }
  }

  // Extract links for specific components
  switch (resource.component) {
    case 'mongo_serverless':
      if (resource.aws_data.groupId) {
        links.push(
          {
            title: 'Atlas Project',
            url: `https://cloud.mongodb.com/v2/${resource.aws_data.groupId}#/clusters`,
          },
          {
            title: 'Mongo Serverless Cluster',
            url: `https://cloud.mongodb.com/v2/${resource.aws_data.groupId}#/serverless/detail/${resource.aws_data.name}`,
          },
        );
      }
      break;
    case 'mongo':
      if (resource.aws_data.groupId) {
        links.push(
          {
            title: 'Atlas Project',
            url: `https://cloud.mongodb.com/v2/${resource.aws_data.groupId}#/clusters`,
          },
          {
            title: 'Mongo Cluster',
            url: `https://cloud.mongodb.com/v2/${resource.aws_data.groupId}#/clusters/detail/${resource.aws_data.name}`,
          },
        );
      }
      break;
    case 'global_mongo':
      if (resource.aws_data.groupId) {
        links.push(
          {
            title: 'Atlas Project',
            url: `https://cloud.mongodb.com/v2/${resource.aws_data.groupId}#/clusters`,
          },
          {
            title: 'Mongo Global Cluster',
            url: `https://cloud.mongodb.com/v2/${resource.aws_data.groupId}#/clusters/detail/${resource.aws_data.name}`,
          },
        );
      }
      break;
    default:
      break;
  }

  const name = normalizeEntityName(getComponentUniqName(resource));
  const cloudName = getComponentName(resource);
  return {
    owner,
    identifier,
    dependsOn,
    name,
    links,
    lifecycle,
    cloudName,
    region,
    componentType,
    tags,
  };
}

export const useIdpAppData = ({ entity }: { entity: Entity }) => {
  let resourceId: string;
  let componentType: string;

  const annotations = entity?.metadata.annotations;

  if (entity.kind === 'System') {
    if (annotations?.[IDP_PROJECT_ID_ANNOTATION]) {
      componentType = 'project';
    } else if (annotations?.[IDP_ORGANIZATION_ID_ANNOTATION]) {
      componentType = 'organization';
    } else {
      componentType = 'unknown';
    }
  } else {
    componentType = (entity?.spec?.type as string) ?? '';
  }

  let metadataUrl = '';

  if (annotations?.[IDP_UI_TABS_ANNOTATION]) {
    if (annotations?.[IDP_UI_TABS_ANNOTATION].split(',').includes('metadata')) {
      metadataUrl = `/${entity.spec?.type}/${annotations?.[IDP_RESOURCE_ID_ANNOTATION]}/metadata`;
    }
  }

  const serviceId =
    componentType === 'service'
      ? (annotations?.[IDP_COMPONENT_ID_ANNOTATION] as string)
      : '';

  if (componentType === 'project') {
    resourceId = annotations?.[IDP_PROJECT_ID_ANNOTATION] ?? '';
  } else if (componentType === 'organization') {
    resourceId = annotations?.[IDP_ORGANIZATION_ID_ANNOTATION] ?? '';
  } else {
    resourceId = annotations?.[IDP_RESOURCE_ID_ANNOTATION] ?? '';
  }

  return { metadataUrl, serviceId, componentType, resourceId };
};
