import {
  configApiRef,
  createApiFactory,
  createComponentExtension,
  createPlugin,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

import { AtlasApiClient, atlasApiRef } from '@internal/plugin-atlas';
import { rootRouteRef } from './routes';
import { ToolsApi, toolsApiRef } from './api';
import { ToolsApiOptions } from './type';

export const toolsPlugin = createPlugin({
  id: 'tools',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: toolsApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
        catalogApi: catalogApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ configApi, identityApi, fetchApi }) => {
        const toolsApiOptions: ToolsApiOptions = {
          configApi,
          identityApi,
          fetchApi,
        };
        return new ToolsApi(toolsApiOptions);
      },
    }),

    createApiFactory({
      api: atlasApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
        catalogApi: catalogApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ configApi, identityApi, fetchApi }) => {
        const toolsApiOptions: ToolsApiOptions = {
          configApi,
          identityApi,
          fetchApi,
        };
        return new AtlasApiClient(toolsApiOptions);
      },
    }),
  ],
});

export const ToolsCard = toolsPlugin.provide(
  createComponentExtension({
    name: 'ToolsCard',
    component: {
      lazy: () => import('./components/ToolsWidget').then(m => m.ToolsWidget),
    },
  }),
);
