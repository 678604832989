import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const serviceCatalogPlugin = createPlugin({
  id: 'service-catalog',
  routes: {
    root: rootRouteRef,
  },
});

export const ServiceCatalogPage = serviceCatalogPlugin.provide(
  createRoutableExtension({
    name: 'ServiceCatalogPage',
    component: () =>
      import('./components/CatalogComponent').then(m => m.CatalogComponent),
    mountPoint: rootRouteRef,
  }),
);
