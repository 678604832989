import { DiscoveryApi, IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import axios, { AxiosInstance } from 'axios';

/**
 * Wrapper around Axios which adds interceptor for adding Phoenix token as bearer
 * and resolves proxy URL for path.
 */
export class CnsPhoenixServiceApi {
  private readonly discoveryApi: DiscoveryApi;

  private readonly identityApi: IdentityApi;

  readonly apiClient: AxiosInstance;

  constructor(options: { discoveryApi: DiscoveryApi; identityApi: IdentityApi }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;

    this.apiClient = axios.create({
      withCredentials: true,
    });

    // associate interceptor middleware
    this.apiClient.interceptors.request.use(async (request) => {
      // URL = resolve proxy URL
      request.url = `${await this.discoveryApi.getBaseUrl('proxy')}${request.url}`;
      // Use credentials as bearer token
      const { token } = await this.identityApi.getCredentials();
      if (!request.headers) {
        request.headers = {};
      }
      request.headers.Authorization = `Bearer ${token}`;
      return request;
    });
  }
}

/**
 * Ref to API we can refer to in React components
 */
export const cnsPhoenixServiceApiRef = createApiRef<CnsPhoenixServiceApi>({
  id: 'plugin.cns-phoenix-service.service',
});
