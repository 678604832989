import { createApiRef } from '@backstage/core-plugin-api';
import { Audit } from '@internal/plugin-audits-common';

/**
 * @public
 */
export const auditApiRef = createApiRef<AuditApi>({
  id: 'plugin.audits.service',
});

/**
 * @public
 */
export interface AuditApi {
  getAllAudits(): Promise<Audit[]>;
  getEntityAudits(resource_id: string): Promise<Audit[]>;
  getAudit(id: string): Promise<Audit>;
}
