import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

import { rootRouteRef } from './routes';
import { SlackApiClient, slackApiRef } from './api';
import { SlackApiOptions } from './type';

export const slackPlugin = createPlugin({
  id: 'slack',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: slackApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
        catalogApi: catalogApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ configApi, identityApi, fetchApi }) => {
        const slackApiOptions: SlackApiOptions = {
          configApi,
          identityApi,
          fetchApi,
        };
        return new SlackApiClient(slackApiOptions);
      },
    }),
  ],
});

export const SlackPage = slackPlugin.provide(
  createRoutableExtension({
    name: 'SlackPage',
    component: () =>
      import('./components/SlackComponent').then(m => m.SlackComponent),
    mountPoint: rootRouteRef,
  }),
);
