import { z } from 'zod';
import { makeFieldSchemaFromZod } from '@backstage/plugin-scaffolder';

/**
 * @public
 */
export const TagPickerFieldSchema = makeFieldSchemaFromZod(
  z.record(z.string(), z.string()),
  z.object({
    type: z.string().describe('Input label'),
    latest: z.boolean().optional().describe('Get latest version only'),
    helperText: z.string().optional().describe('Helper text to display'),
  }),
);

export const TagPickerSchema = TagPickerFieldSchema.schema;

export type TagPickerProps = typeof TagPickerFieldSchema.type;

/**
 * The input props that can be specified under `ui:options` for the
 * `OwnersPicker` field extension.
 *
 * @public
 */
export type TagPickerUiOptions = typeof TagPickerFieldSchema.uiOptionsType;
