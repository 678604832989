import React from 'react';

const LogoIcon = () => {
  return (
    <svg width={48} viewBox="0 0 72 72">
      <image
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAAsTAAALEwEAmpwYAAAU2UlEQVR42uWcfXCdV33nP79zznNf9Ga92E7sxI5tHMu2SGmq
AMmUWa4hSctbM4TIQDstUGhDy0I3pJRp2e6K7XTo7HTTQEshlKaFaUsrYwcKmzROqG+mO+x2GpEE
IsfQQBw7xElsSbYs6d77POec3/5x75Ut27Ik27Kz7G/mmbl69Dzn5Xt+5/d+HniZkA5h9Z7+ZOZv
kEs9ppcFqSI61JebdW+orw1eHiC5S9m57ik5kbKHkVTv35gPVXtbjPy2l+wwcLOAKoiA/n8FkCrC
F/qdbCtnAOnO3vf7Ch93RXO1LRqyUf/9Ew9zSfnoogOkQ1g+icrgcJbu2vQqUT7vWsz1BPCVmLo0
5gTSSwfJbDIXFZw9JSfbCTJIDPf1/pZFHnct9no/Hb2vRQ9qMYIIy3ToyiIAn7y0cuiiAaR7Sk62
lT2A37n5S6bN3g3gp2NGnZMdiCEqCl3kW7vqb5Yu6iJeEoCa4Khisp2bH7LLzK/4iZDGSBRhRrUj
4ANYQ3uWxTUAe7ce/skGSIewM5xzX+9u12Fu9EdDFUgQjJ6snxRBiZI3GGO2Amxtq/3kbjEFYYAI
4Hdt/orrsG/0E6GGUGAu3SREDKC8FmD44LJ4KQFaWi12T78TGc6ynb3/2babd/mjIUPIz/tegAg/
q4oRGc50CCvbCZcCoCXjIB3qy8ntw1m28+r/4Jz8gZ8IINj5X8TEWsQIr8y+tuk6AMb7L5kcWpKO
VTGyfSTVPSUH8uc4gbptM39/golKatssEuUdAOVD7Xqp3I6lWZlyXTX70UN3uA7X52uxBuQW0YJQ
iwj8kt6/sWPbYNkz+BMCkCoi28peh/q6ET5OJQIL2FqzKfGpprbDXpFNm18BYFX/Ytt4eQLEcL8D
8DZ80HXYHp9pKuekDNRSjRiR39GhK4ty+3CmevG56LwA0qHZnKGKyHXDmT7anwj6fioRFKfn4IsL
Yn1NM9tu16Sm9RMAPLBxMdv00gGkiuggRrYTZq3qjr4EIOyf+nlbMBt8qhE5xz7qKLk4GTDCJ9Kv
brxW3vx0Te/fOL+ZcCkBqtsmqAwSdWjDMhF0BqQVKyJAFH07lrrRd34kUfEuL4Ddqfdv7JA3P107
NcD2sgFIh7DSmHS2c9MfZ9Z9bwakz2zMy7ay//fPbMwL/CxeAT27zFDQOG84zPmapkmbWe8rbrcO
lpxsH0lPDs++LABqbikAv7P3s67d3Zm02jXBuVsAuDyfAKxdbXtBX6GZgspZARKj2LwHaQA1N+X8
VExdu3mtv+bQbgC5fTi7GCAtCCAF4b/Wf2c7e//Edtrf9MfDNAFU5UMArFhRbUy7zxWtDZGIzKF1
FESUmJlQPdSWIjG6ggcVNAqq9VjraSAdD6lrt9uynb3faoI0ssTbbWEc9Gi/EyFmX930Uddh/5M/
GjxQ8NUQXKt5jd+56ZebHrshXt2wnMNcSllVEBfJjhY5cvtKM/Y/V/nqSy2pOB9twWOTiLEKMvsS
ozk/6VO3zLwh27XpX3SEXN/2kVQVoyB7SiW3p1RygxfQfJnXrtChvpxsH0mzXb1vcE6+5TMFiFIP
VXiXiPOZjjqX9MktT77od276K9vm3uuPh4yTYz0ntxkFk/fUnm+Lo4MrRV9A5CoN+W2pL2yZJlle
M7bgkSQ6QOqiTBWVBl9pLWm1xWxK/y07VLyl9Y7hQ6f2MdI3kOsb2XHeoduzGnCNQFeqX+9tD54v
ISCQKSRRwQguZJq6NtvjJ7O/A94YoWAN82owQRAXM9rUmmtwCrb6pYKtaEFllUZzZQymO9Z5ITYv
aS5pPqbU2nvk1ZNjuvfAhpueliIHQpT/44zsvnLkwSf6RnakWio5yuUonLs2nZODVBF2DBjZviP4
nb2ftx32dj8RUs7gU4ngbZt12YT/CxFWuzb3Fj8ZMjgLByWRMO384c+tIj5jHd115hAL1ICqEI83
gLGNkVpmNJ5Y0HFCslJssc3gtC7wxkKmKLsDfGr9voceAdhTKrlt5boIuHAANcKk6X2bXpOI+Vcf
FJTTDD8R0FjnGJeI8ZkG5vG9mskuTIyjX1vla39byEmf1oHR+ttiULUnje8UoS2KYhACQb0ERBUQ
g+TaraMaAzWNn7/3qYc+NAjx0f7+5Lrh4WyxAM0tzB4pRwAJ/D5FA1CT061i1agBU7d7fRaDLCDm
Iw21blw0xa0VVd+YvjRGpKAeoQYzVzr70gzRGmjAimgOyKPkYtRwzPu0plF7bO6Dv7rlpv/9ww03
LrtueDh7tH/xZsEZAdIhrAwS052bXu0S81Y/GRTINRdRlYYWyuvhL19Zi9XEGxsFFaO6sCyoGFX1
luL649a9wXtGBbGceFsWeDXGgwGKIC1Y8ppDYTSk1W6bvCaXk0eeWVcqXDc8nCkDi4oKnJmDGhE8
o/JBigYUL6dsRxFFKzar/mO+OPHt7qCidbtn4R63RG+wRe+WveVYjM9JxJ1DmlkBBzolxMcM4TFD
fEKgvj0LYz6rLE9yr7LF5G/rL+xYlMA+DSAdwsrtw9mxob5uFX6B6QiCO3nUIvVJaGa86whx6rPF
/NST3anJeVQXHpEQo8SapWXjsaTtjqnUP26gsMiQRgI6LtgrAp3/7Sg9dx+h5Tcq6IvS3LLFwz5N
u2xy67NbbvplAV3MVjsNoKdf2OgAWo2/0RXMcu8bIvhkEBtcEhV0QtRdqxz9o86k8kxn1RazGWt4
IaSABmuWve6lpPX2Si0+K3PovjOQBaogXZHu9x6mvf8wxfUT9Lz5eVrfM0ncK0i9LVuJAYFPan9/
8s3h4fDMulJBB+bfbqcBtPGaK+vZA+Hm+Txy46KDuoiWy9WOfao7qfxoWdUWs6aXMS9MIhCDYHLB
FjZOw7hEOUm+zDf6+O9C/oaU3MppwnSOWHVoFFq2TmI2KrECYrBVjb7DuPXPTXUNDEJcv79clR07
wqP9/YkyOKeymmUoNpN8qojfxasJjYGeYZqqgimolS5VrYK0qKoaO/pfltP1cVdt7RvNabAmelN3
E+Z0O0CsopkNU4+3CmDU6MlG4TwIA1n9QTGKRkEMEAXNQIozw7fTGlCRPzqw+aYbMPKYeP/gmuF/
/jEM0zAqw6kycDZyDeFc3bVlrQjr8MqcQkUFk/eRVTFKJuAFKaqaVWrHf7crN7Z7dRprNrMFjzF1
tX6mrSeNiflKov7pRGStQligGApgepXaIzmqz3Zg8hmmkKGZMDncgR4QyNO0oyUoMRGzptMl/7FN
7F+qtfsObL7p3mc2//w6KZf9DgbM4CmYzOKgvV1Vady8yhrpCF7hDGvf3Dy2GIxdozF8B1itQrUu
P8yropn6s5ZC9YnV2bK3HasVN0xak8+cqCF6MxPaEGlkcyRKmHJRnzZGLlcIdX92IQDhQKvC2Od6
aHlTEdflqTxZJP1GDtOnswppFDWZajwaYkQRI9K2zLn3jYfsl57dfNNHrtq3457G1Ga06Sy0tm4o
aINVryAnaB37Mw5UoyCJN25NpvGwnHAHAmgNzE8HdL9Nxn+nJzn85dVx6smeWjaRy8SGaAsZNu8x
SRSxKiKK1qyNo1i1J5TAvCSAB2lVCDB5Vxvjv99J+o0c8or6vTOQARyCjaiOhSx1Irlul3z+wJYb
72o0O5OHm73Fhhv9RpZzNodTABXEqeTXpoCq6EkyQ4CKIJ2q5tposrLLjf9eZ+7wp1fJka+v9scf
W1GrPNeeZsfyaZi2mUZRzSScaHtB8JzoKwAJ2J8JuP6AXK2wMKdCLJLzqtO1urJ+zzPrSoX6vwab
u+kk2tRej2VobDXGnXWgqkBEcqsqYta2R61gcZxYNVN3B/AgGyJGRXTUuNpfF6hqQaUdlSs1mq6I
dCpaNc5sUjmn2jIBIui0zL43zysoIYpmbca1TEZfVZWb1+8vV3VgwMqOwXA6QDNvm2bzOueKiqLB
knRVbPJqH9LdiZUNp7B1s5WqoAZoVeSautQhIuIx8QWL/ggkj9Cq51euubCNqUBQCCLkl9ucHQ3p
wSh6y7qnHnqsDs6OmVnM3mI/ON7sIp1xH+cY8Iz9kg+2eO2U6vGzTK0JcqDO+mn9twpQUGS5Qtt5
grMwUgFpNdb12FxekXjEp/e0ZO6V6/Y+/Jj29ycngwNzcJCgR4nNrMRZlqXBRS0bJs3kDe0+vmgT
ijp/eOrkJhXwLHT1zwuc+ojJpmMYmRT/T2r58ronH34KQPv7EzlDOOSMah7iC/i6hdJs+IzzFIiZ
wbTVktYbp2vH/qBD7bVRdFoWFxW+OAllTUTEwy1r9u5+4ARqA7ZcOixSLp9RrM8CqKnmrbUHfKre
GlyI8/CDKARL6zVH7dQbW7I4YnOyUlVTFmLJLDk15HfaZZPcUZ89uHbfQw8oSLlUsofLKxR2xG3l
uTf37HU+3t6QPLpf0UPi5te5dVlksIXMdf7CuMYXJCKIGBYYGVpyhLxFchMhQ9CPATAwYLaVy347
O05zLc4OUKkcVBG55fvHReTJevpGFuBwKjG1FNZN5FrvmMrCEwZaFhH3WAqKIJaoBtNpEoLoR9bs
e/h7Wiq5UwXxggESQZsFCMAjDWNhQROtG1BWOl932OZvS2vxUQutCxDYS0EKUlTVTCj+2Jkxk951
1d6H/3RoYMBSLi+q1vF0UTpel0Mu8oCfijTyDPOSCEQvGBdd99tfNPYNPtUnLgFICiQQv2s1VxMj
75t4dO33HroTYIC6G7GY5s7sZykigma7er/tCuYGX40enTuH1vTQpe6CqEmCZMfy2ZG/vEzDIy4n
Px2hysXRVhaYFNxmP7XqnUdbuXzs9+RN+z+ln9mYl488XVtsc2dWxl+oV4mp6r1YgXj2fWZcI1Vc
h0tCzZF0pMnyD7wgyduyVB83C48Sng854JhgNoR0+XufL8Tlx9Kj+zu/AsCHn150ymdugH592AMk
ufa/yyb9QZeXROvm3OmkaHas4GNqvdgQTS6oLaRgouZ6KsnKXz0oxd+cqugouuhKxXOYjR4UWl5T
qSarog3T+b/p+o3H9+senJxjrdKZLWlBG4nD6XRX7x9TMJ8mDbM6OJEdTcKRz14WpU0l15tmrssb
U9SgaNTUJNRE46QVKYoSl1CzCUgVuDrWWrZOtIZx1Zi6/w7A4XM3OM6WejYiRN1Tctno808kLXar
r8QTqedGjZxJfBzfc5mfvLs9J60RnWoUGiAKKoqIAWRrXDpfKwKtSvyOpf1jxye63zLa4V+Su5Lt
++7UwZKTwXNLO8NZihdEiDrUl5Nt5TS7b/MdBH2w8Xzd9RDQqKDGdLx2TCqvL2QcNokWI5KJYLTu
6FpFA/Xs6FLwTwQpqOqLRtzrwnT3a8c7wjgHxrH1iqatK85rWc7qMTWr5ZO379udpfFPXYc1NELk
jUQ4ITPYtizpvHU8hr0mihfUo1oDTUErSwiOgiQoQUR/jO9896ilPeBr8msrt49M1kt3Fm4ULhog
YCZHn3vHDz7iJ+K/ujabU6g1I0b15J+jZf1EruMTE6l/wqgUGn7YyWnkJQAHCyRIeNJox+BEpfWV
0/n0qPlk4V37dus9/YlsHznv+qCFWcmNSo/ju16xsqDuMVc0q30lVgUKyolqDTEhjpcvT6c+3Zq3
PxOFjLrTeqHL1SNIDsUi/nGj7R+dmuh525Fl2Shfyd227xdV6wt0IU5LL2jojaMFufZbf/hSUFPy
lTjmiqagShVm8h6KWtNVejHXdudkLXzHKB6h5QIGwrQBTosqmUh43ISOjx2f6HnrkWV+TO/P3bbv
FwHYgblQR8kXxfzNcrzqV7dcbSXucW32Cj8ZajBzBkxFELEhTn63Jz121zKnk+KkN55/UKwpbxJE
nzBol9Y67zzqO2441urHZFdy6753wOyzsRcdIAB9tD+R64YzvX/jCl8x/+iWuev9sdAsknPN8JrJ
ea0dasuOPtCN/zeX0Ik081j1hDwnfLS5QnKGmZohEdCXBD0kIXdbrdL5prG2wvoqfszcldy2706A
PYMlt+08VPoFAQhOcBJAtqv3bpczvwXga7FpzjtUxOQC0Ysf/YfV1B7OOblc0f2C9ADtirgGCCia
CjOp7lwjjJ0B04I+J6gnJDdn1fYbj5v2a44XQxqmQsV9IP+up/6+PqYBe74a64IBBLNZubrr6pss
9m7XaraSaR0oRVUlsXkvR765Ola+UDT2+qDJ1Wnmn0lM+LYzGlWaEsy8QusiP4DuM3Wm6olqezVN
tqRpyzVTSdvG6QKJJ0zJLltr/bC8+7HndU/JUSrHc3Ul5qNzPrPaLHKgXLKyrfyQKtfEr/V+OETu
SNrsVUTFT0c0SoqKAYzk0O43HzGIUhso+nA0IRtLIlVjayM5E583lhYNLR+aqiWXZ5p0pbjuWmvu
spAnRPyk/AtTuT9M3jnyIIDe0580P2+xVHReh3rrhVR1DScyksL3P60P/tQXw1Ttnaq8T+B1tsPm
TN1JVaJK9EaSZRUprsvqYdkogsR4uHJFlv2vlhau977r9aMt9jIPwRAmdDIcNd/M0uSLxXfv/RY0
jmHtLYncvrTgnDdAM0BtH0l1CMuGfiPXDU8B9wL31v5+yyulUrs5TJg7HbIaJOTzxlLMg28UQBsa
ZbKkEcWImqzifqgv8R1fdQ8UfHxA3jfyQrMvHerLMTCSiZQvSsT7gtu4OoRlRUnYdqLW5tmrbv7G
8vbkraOaji3/6KEPFDf92MfRno3Ru05UvcmH0UN/tvbXW39QeNVkRzaeD9nalSPlyZk2BzG8vmR4
pBxl8OIGcZcs/KAgDJasDJb9wb6b/qlTkp8b9368ts++fZq2A/fRemiQv642nz+49uZv9HQmbz2S
puNak/fIGn/MLefZ1bv++dlL+Q2hpT2PPlgPkGuAaowg2tJyrS9fft2xH72//7mxg9tuvBVAFUuH
5io+gGhBivp1XrLlbMR+d/+WG98ioAupJ/x/D6BGCYmKdCx3OdqMy7enCW7acGXWVtRD5n8AiBA0
sKw7ydNiXLHbJNJmnawttHYY5HMAi0nVXEhask9TnFyEJMIXD2bTB0Am61k0wnQ62YKRvcqgEQaj
IH/xXDp9IMKxaaIRCMdrky0iPHUpgLlotJAvJrwcPuY2F12Uge0plVwJKJ9yvwRI4xTOQp65FPR/
AS4WTXafqXSAAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTAyLTIwVDIwOjI2OjIyKzAwOjAw9MpG
+wAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wMi0yMFQyMDoyNjoyMiswMDowMIWX/kcAAAAASUVO
RK5CYII="
      />
    </svg>
  );
};

export default LogoIcon;
