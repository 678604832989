import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
  ProfileInfo,
} from '@backstage/core-plugin-api';
import {
  IdpAwsAccount,
  IdpEnvironment,
  Task,
} from '@internal/plugin-catalog-module-common-idp';

export const idpApiRef = createApiRef<IdpApi>({
  id: 'plugin.idp-frontend.service',
});

export type Options = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
  proxyPath?: string;
};

export type ServiceSecretsResponse = {
  secrets: any[];
};

export type EcrImage = {
  artifactMediaType: string;
  imageDigest: string;
  imageManifestMediaType: string;
  imagePushedAt: Date;
  imageScanFindingsSummary: Record<string, any>;
  imageScanStatus: Record<string, string>;
  imageSizeInBytes: number;
  imageTags: string[];
  lastRecordedPullTime: Date;
  registryId: string;
  repositoryName: string;
};

export type Ecr = {
  images: EcrImage[];
  metadata: Record<string, any>;
  provisioned: boolean;
  identifier: string;
  updated_at: Date;
  created_at: Date;
  environment_component: IdpEnvironment;
  aws_account: IdpAwsAccount;
  cloud_id?: string;
  status: string;
  settings: Record<string, any>;
};

export type Graph = {
  title: string;
  embed_id: string;
  html: string;
};

export type GraphResponse = {
  graphs: Graph[];
};

export type RecentCostResponse = {
  reports: any[];
  service_names: any[];
  usage_types_in_service: any[];
};

export type IacVersion = {
  value: string;
  deprecated: boolean;
  recommended: boolean;
  latest: boolean;
  unsupported: boolean;
  description?: string;
  hasBreakingChanges?: string;
};

export type Revision = {
  component: string;
  _id: string;
  data: object;
  revision: number;
  created_at: Date;
};

export type Log = {
  _id: string;
  component_id: string;
  data: string;
  revision?: number;
  created_at: Date;
  level: string;
};

export class IdpApiClient implements IdpApi {
  // @ts-ignore
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async refreshVault(component_id: string): Promise<ServiceSecretsResponse> {
    return await this.get(`/service/${component_id}/vault`);
  }

  async copyVaultSecret(
    component_id: string,
    payload: { [key: string]: string },
  ): Promise<any> {
    return await this.post(`/service/${component_id}/vault`, payload);
  }

  async getComponentGraph(
    component: string,
    _id: string,
  ): Promise<GraphResponse> {
    return await this.get(`/graph/${component}/${_id}`);
  }

  async getRecentCost(
    componentType: string,
    resourceId: string,
    granularity: string,
  ): Promise<RecentCostResponse> {
    return await this.get(
      `/cost-explorer/costelement?component=${componentType}&_id=${resourceId}&granularity=${granularity}`,
    );
  }

  async refreshECR(component_id: string): Promise<Ecr[]> {
    return await this.get(`/service/${component_id}/ecr`);
  }

  async getSemlaTags(filter_by?: {
    [key: string]: string;
  }): Promise<IacVersion[]> {
    const iac: any = await this.get(
      `/constants/git_terraform_tag?${new URLSearchParams(filter_by)}`,
    );
    return await iac.data;
  }

  async getRevisions(
    component_id: string,
    revision?: number,
  ): Promise<Revision[]> {
    if (revision) {
      const rev: any = await this.get(
        `/revision/${component_id}?revision=${revision.toString()}`,
      );
      return await rev.revisions;
    }
    const rev: any = await this.get(`/revision/${component_id}`);
    return await rev.revisions;
  }

  async getComponentById(
    component: string,
    serviceId: string,
  ): Promise<Record<string, any>> {
    return await this.get(`/${component}/${serviceId}`);
  }

  async updateComponent(
    component: string,
    payload: any,
  ): Promise<Record<string, any>> {
    return await this.put(`/${component}`, payload);
  }

  async discoveryComponent(
    component: string,
    payload: any,
  ): Promise<Record<string, any>> {
    return await this.post(`/${component}/discovery`, payload);
  }

  async getLog(
    serviceId: string,
    revision?: number,
    fullLog?: boolean,
  ): Promise<Log[]> {
    let logs;
    if (revision) {
      if (fullLog) {
        logs = await this.get(
          `/log/${serviceId}/full?revision=${revision.toString()}`,
        );
        return await logs.logs;
      }
      logs = await this.get(
        `/log/${serviceId}?revision=${revision.toString()}`,
      );
      return await logs.logs;
    }
    if (fullLog) {
      logs = await this.get(`/log/${serviceId}/full`);
      return await logs.logs;
    }
    logs = await this.get(`/log/${serviceId}`);
    return await logs.logs;
  }

  async refreshKubernetes(component_id: string): Promise<Record<string, any>> {
    return await this.get(`/service/${component_id}/kubernetes`);
  }

  async getCircleCi(component_id: string): Promise<string[]> {
    const result = await this.get(`/service/${component_id}/circleci`);
    return await result.branches;
  }

  async getTasks(scheduled?: boolean): Promise<Record<string, Task[]>> {
    if (scheduled !== undefined) {
      return await this.get(`/task?scheduled=true`);
    }
    return await this.get(`/task`);
  }

  async getComponentsTree(
    component: string,
    resourceId: string,
    showSubcomponents: boolean,
  ): Promise<any> {
    if (showSubcomponents) {
      return await this.get(
        `/${component}/${resourceId}/tree?showSubcomponents=true`,
      );
    }
    return await this.get(`/${component}/${resourceId}/tree`);
  }

  async getComponentSchema(component: string): Promise<Record<string, any>> {
    return await this.get(`/schema/${component}`);
  }

  async getComponentConfigs(
    id: string,
    filter_by?: { [key: string]: string },
    config_name?: string,
  ): Promise<Record<string, any>> {
    if (filter_by) {
      return await this.get(
        `/config/${id}${
          config_name ? `/${config_name}` : ``
        }?${new URLSearchParams(filter_by)}`,
      );
    }
    return await this.get(
      `/config/${id}${config_name ? `/${config_name}` : ``}`,
    );
  }

  async getComponents(
    component: string,
    filter_by?: { [key: string]: string },
  ): Promise<Record<string, any>> {
    if (filter_by) {
      return await this.get(`/${component}?${new URLSearchParams(filter_by)}`);
    }
    return await this.get(`/${component}`);
  }

  async getCatalogTools(): Promise<any> {
    return await this.get('/catalog_tools');
  }

  async getExecutors(component_id: string): Promise<any> {
    return await this.get(`/service/${component_id}/executor`);
  }

  async getDependencies(
    resourceId: string,
    componentType: string,
  ): Promise<any> {
    return await this.get(`/${componentType}/${resourceId}/dependencies`);
  }

  async get(path: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/phoenix-idp-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }

    return await response.json();
  }

  async updateKubernetes(
    component_id: string,
    payload: { [key: string]: string },
  ): Promise<Record<string, any>> {
    return await this.put(`/service/${component_id}/kubernetes`, payload);
  }

  async circleCiRunBuild(
    component_id: string,
    payload: { parameters: { [key: string]: string }; branch: string },
  ): Promise<Record<string, any>> {
    return await this.put(`/service/${component_id}/circleci`, payload);
  }

  async markBlueprint(
    component: string,
    resourceId: string,
    payload: { [key: string]: any },
  ): Promise<any> {
    return await this.put(
      `/${component}/${resourceId}/tree/mark_blueprint`,
      payload,
    );
  }

  async getBitbucketRepo(component_id: string): Promise<any> {
    return await this.get(`/service/${component_id}/bitbucket`);
  }

  async getFilesInRootFolder(
    component_id: string,
    branch: string,
    folder?: string,
  ): Promise<any> {
    if (folder) {
      return await this.get(
        `/service/${component_id}/bitbucket/browse/${branch}/${folder}`,
      );
    }
    return await this.get(
      `/service/${component_id}/bitbucket/browse/${branch}`,
    );
  }

  // DOWNLOAD
  async downloadDebugCode(
    component: string,
    component_id: string,
  ): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/phoenix-idp-backend/debug/${component}/${component_id}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });
    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }
    return await response.arrayBuffer();
  }

  // POST
  async executeTerraform(
    component_id: string,
    payload: Record<string, string | string[]>,
  ): Promise<Record<string, any>> {
    return await this.post(`/service/${component_id}/terraform`, payload);
  }

  async post(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/phoenix-idp-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'POST',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }

    return await response.json();
  }

  // PUT
  async sendTask(task_name: string): Promise<Record<string, Task[]>> {
    const payload = {
      component: task_name,
    };
    return await this.put(`/task`, payload);
  }

  async put(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/phoenix-idp-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'PUT',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }

    return await response.json();
  }

  // DELETE
  async deleteEcrImages(
    component_id: string,
    payload: Record<string, string[]>,
  ): Promise<any> {
    return await this.delete(`/service/${component_id}/ecr`, payload);
  }

  async deleteKubernetes(
    component_id: string,
    payload: { [key: string]: string },
  ): Promise<Record<string, any>> {
    return await this.delete(`/service/${component_id}/kubernetes`, payload);
  }

  async delete(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/phoenix-idp-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'DELETE',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }

    return await response.json();
  }

  async getProfile(): Promise<ProfileInfo> {
    return await this.identityApi.getProfileInfo();
  }
}

export interface IdpApi {
  get(path: string): Promise<any>;
  refreshVault(component_id: string): Promise<ServiceSecretsResponse>;
  copyVaultSecret(
    component_id: string,
    payload: { [key: string]: string },
  ): Promise<any>;
  getComponentGraph(component: string, _id: string): Promise<GraphResponse>;
  refreshECR(component_id: string): Promise<Ecr[]>;
  deleteEcrImages(
    component_id: string,
    payload: Record<string, string[]>,
  ): Promise<any>;
  getRecentCost(
    componentType: string,
    resourceId: string,
    granularity: string,
  ): Promise<RecentCostResponse>;
  getSemlaTags(filter_by?: { [key: string]: string }): Promise<IacVersion[]>;
  getRevisions(component_id: string, revision?: number): Promise<Revision[]>;
  getComponentById(
    component: string,
    serviceId: string,
  ): Promise<Record<string, any>>;
  updateComponent(
    component: string,
    payload: any,
  ): Promise<Record<string, any>>;
  discoveryComponent(
    component: string,
    payload: any,
  ): Promise<Record<string, any>>;
  getLog(
    serviceId: string,
    revision?: number,
    fullLog?: boolean,
  ): Promise<Log[]>;
  refreshKubernetes(component_id: string): Record<string, any>;
  updateKubernetes(
    component_id: string,
    payload: { [key: string]: string },
  ): Record<string, any>;
  deleteKubernetes(
    component_id: string,
    payload: { [key: string]: string },
  ): Record<string, any>;
  getBitbucketRepo(component_id: string): Promise<any>;
  getFilesInRootFolder(
    component_id: string,
    branch: string,
    folder?: string,
  ): Promise<any>;
  downloadDebugCode(component: string, component_id: string): Promise<any>;
  getCircleCi(component_id: string): Promise<string[]>;
  getComponentSchema(component: string): Promise<Record<string, any>>;
  getComponentConfigs(
    id: string,
    filter_by?: { [key: string]: string },
    config_name?: string,
  ): Promise<Record<string, any>>;
  getComponents(
    component: string,
    filter_by?: { [key: string]: string },
  ): Promise<Record<string, any>>;
  getCatalogTools(): Promise<any>;
  circleCiRunBuild(
    component_id: string,
    payload: { parameters: { [key: string]: any }; branch: string },
  ): Record<string, any>;
  markBlueprint(
    component: string,
    resourceId: string,
    payload: { [key: string]: any },
  ): Promise<any>;
  getTasks(scheduled?: boolean): Promise<Record<string, Task[]>>;
  sendTask(task_name: string): Promise<Record<string, Task[]>>;
  getComponentsTree(
    component: string,
    resourceId: string,
    showSubcomponents: boolean,
  ): Promise<any>;
  getExecutors(component_id: string): Promise<any>;
  getDependencies(resourceId: string, componentType: string): Promise<any>;
  executeTerraform(
    component_id: string,
    payload: Record<string, string | string[]>,
  ): Promise<Record<string, any>>;
  getProfile(): Promise<ProfileInfo>;
}
