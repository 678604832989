import { createApiRef } from '@backstage/core-plugin-api';
import { UsersResponse, AppliancesResponse, CDCUserResponse } from './types';

/** @public */
export const UserPageApiRef = createApiRef<UserPageApi>({
  id: 'plugin.user-page.service',
});

/** @public */
export interface UserPageApi {
  get(path: string): Promise<any>;
  getUsers(url: string): Promise<UsersResponse>;
  getAppliances(url: string): Promise<AppliancesResponse>;
  getCDCUser(url: string): Promise<CDCUserResponse>;
  getDeltaAppliances(url: string): Promise<any>;
}
