import {
  IdentityApi,
  createApiRef,
  ConfigApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { AtlasApiOptions } from './type';

export const atlasApiRef = createApiRef<AtlasApi>({
  id: 'atlas',
});

/**
 * The Atlas API provides information about Atlas
 *
 * @public
 */
export type AtlasApi = {
  /**
   * Invite Atlas user
   */
  inviteUser(userEntityRef: string): Promise<any>;
  /**
   * Get Atlas user info
   */
  getUser(userEntityRef: string): Promise<any>;
  /**
   * Delete user from Atlas
   */
  deleteUser(userEntityRef: string, atlasUserId: string): Promise<any>;
};

export class AtlasApiClient implements AtlasApi {
  private configApi: ConfigApi;
  private identityApi: IdentityApi;
  private readonly fetchApi: FetchApi;

  constructor(options: AtlasApiOptions) {
    this.identityApi = options.identityApi;
    this.configApi = options.configApi;
    this.fetchApi = options.fetchApi;
  }

  async inviteUser(userEntityRef: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/atlas/users/user`;

    const payload = {
      userEntityRef: userEntityRef,
    };

    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    const result = await response.json();
    return result;
  }

  async getUser(userEntityRef: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/atlas/users/user?userEntityRef=${userEntityRef}`;

    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    const result = await response.json();
    return result;
  }

  async deleteUser(userEntityRef: string, atlasUserId: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/atlas/users/user?userEntityRef=${userEntityRef}&userId=${atlasUserId}`;

    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    const result = await response.json();
    return result;
  }
}
