import { createApiRef } from '@backstage/core-plugin-api';

/** @public */
export const labMateApiRef = createApiRef<LabMateApi>({
  id: 'plugin.labmate.service',
});

/** @public */
export interface LabMateApi {
  get(path: string): Promise<any>;
  getEmail(): Promise<any>;
  post(path: string, payload: { [key: string]: any }): Promise<any>;
  put(path: string, payload: { [key: string]: any }): Promise<any>;
  delete(path: string, payload: { [key: string]: any }): Promise<any>;
}
