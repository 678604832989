import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { ApplRegApiClient, applRegApiRef } from './api';

export const applianceRegistrationPlugin = createPlugin({
  id: 'appliance-registration',
  apis: [
    createApiFactory({
      api: applRegApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new ApplRegApiClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const ApplianceRegistrationPage = applianceRegistrationPlugin.provide(
  createRoutableExtension({
    name: 'ApplianceRegistrationPage',
    component: () =>
      import('./components/MainComponent').then(m => m.MainComponent),
    mountPoint: rootRouteRef,
  }),
);
