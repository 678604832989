import { HaclEventsCountPageApi } from './HaclEventsCountPageApi';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { BASE_URL } from '../config';
import { buildUrl } from '../utils';

const toQueryString = (params: any) => {
  const queryString = Object.keys(params)
    .map(k => `${k}=${params[k]}`)
    .join('&');
  return queryString;
};

export class HaclEventsCountPageClient implements HaclEventsCountPageApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async getHaclEvents(filters: object): Promise<any> {
    const queryString = toQueryString(filters);
    const response = await this.get(
      buildUrl(BASE_URL, {
        path: `/search?${queryString}`,
      }),
    );
    return {
      data: response.body,
      status: response.status,
    };
  }

  async get(path: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }

    const body = await response.json();
    return { status: response.status, body };
  }
}
