import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const PersonRemoveIcon = () => {
  return (
    <SvgIcon>
      <path d="M14,8c0-2.21-1.79-4-4-4S6,5.79,6,8s1.79,4,4,4S14,10.21,14,8z M17,10v2h6v-2H17z M2,18v2h16v-2c0-2.66-5.33-4-8-4 S2,15.34,2,18z" />
    </SvgIcon>
  );
};
