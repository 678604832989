import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  fetchApiRef,
  configApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { TeamsApi, teamsApiRef } from './api';
import { TeamsApiOptions } from './type';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { rootRouteRef } from './routes';

export const teamsPlugin = createPlugin({
  id: 'teams',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: teamsApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
        catalogApi: catalogApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ configApi, identityApi, fetchApi }) => {
        const teamsApiOptions: TeamsApiOptions = {
          configApi,
          identityApi,
          fetchApi,
        };
        return new TeamsApi(teamsApiOptions);
      },
    }),
  ],
});

export const TeamOwnerCard = teamsPlugin.provide(
  createComponentExtension({
    name: 'TeamOwnerCard',
    component: {
      lazy: () =>
        import('./components/TeamsOwnerComponent/TeamsWidget').then(
          m => m.TeamsWidget,
        ),
    },
  }),
);
