import { createPlugin, createRoutableExtension, createApiFactory, discoveryApiRef, identityApiRef } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { EcpAppliancePageApiRef } from './api/EcpAppliancePageApi';
import { EcpAppliancePageClient } from './api/EcpAppliancePageClient';

export const AppliancesPlugin = createPlugin({
  id: 'appliance-page',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: EcpAppliancePageApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new EcpAppliancePageClient({ discoveryApi, identityApi }),
    }),
  ],
});

export const AppliancesPage = AppliancesPlugin.provide(
  createRoutableExtension({
    name: 'AppliancesPage',
    component: () => import('./components/MainPage').then(m => m.MainPage),
    mountPoint: rootRouteRef,
  }),
);
