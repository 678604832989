import { DiscoveryApi, createApiRef } from '@backstage/core-plugin-api';
import { DocumentationCard } from '../dto/documentation.types';
import { Document } from './types/document.types';
import { PDCLProjectData } from './types/pdcl.project.types';
import { AdpApi } from './adpApi';
import { getConfluencePages } from './getConfluencePages';
import { PDCLDeliverable } from '../dto/pdcl-deliverable.types';
import { getPDCLDeliverables } from './getPDCLDeliverables';

export class AdpApiClient implements AdpApi {
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = options.discoveryApi;
  }

  async get(path: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('adp')}${path}`;
    const response = await fetch(url);

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }

    return await response.json();
  }

  async getPages(
    confluenceSpaceId: string,
    titleLabelPrefix: string,
  ): Promise<DocumentationCard[]> {
    return await this.get(
      `/documents/confluence/${confluenceSpaceId}/pages?details=true`,
    )
      .then(doc =>
        Array.prototype.concat(
          ...doc.map((docItem: Document) => {
            return getConfluencePages(
              docItem,
              [] as DocumentationCard[],
              titleLabelPrefix,
            );
          }),
        ),
      )
      .catch(() => [] as DocumentationCard[]);
  }

  async getPageById(confluenceSpaceId: string, pageId: string): Promise<any> {
    const resp = await this.get(
      `/documents/confluence/${confluenceSpaceId}/pages/${pageId}`,
    );
    return resp;
  }

  async getProjectData(
    confluenceSpaceId: string,
    pdclLabel: string,
  ): Promise<PDCLDeliverable[]> {
    return await this.get(
      `/pdcl-projects/spaces/${confluenceSpaceId}/labels/${pdclLabel}`,
    )
      .then(data =>
        getPDCLDeliverables(data as PDCLProjectData[], [] as PDCLDeliverable[]),
      )
      .catch(() => [] as PDCLDeliverable[]);
  }

  async getDocumentationData(
    confluenceSpaceId: string,
    documentationLabel: string,
    titleLabelPrefix: string,
  ): Promise<DocumentationCard[]> {
    return await this.get(
      `/documents/spaces/${confluenceSpaceId}/labels/${documentationLabel}`,
    )
      .then(doc =>
        Array.prototype.concat(
          ...doc.map((docItem: Document) => {
            return getConfluencePages(
              docItem,
              [] as DocumentationCard[],
              titleLabelPrefix,
            );
          }),
        ),
      )
      .catch(() => [] as DocumentationCard[]);
  }
}

export const adpApiRef = createApiRef<AdpApiClient>({
  id: 'plugin.adp-phoenix-service.service',
});
