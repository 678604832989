/***/
/**
 * Common functionalities for the phoenix-common plugin.
 *
 * @packageDocumentation
 */

import {
  BackstageUserIdentity,
  ProfileInfo,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
/**
 * In this package you might for example declare types that are common
 * between the frontend and backend plugin packages.
 */
export type CommonType = {
  field: string;
};

/**
 * Or you might declare some common constants.
 */
export const AZURE_AD_GROUP_DPO_SRE = 'group:default/dpo-team-sre';
export const AZURE_AD_GROUP_DPO_DEVELOPERS = 'group:default/dpo_developers';
export const AZURE_AD_GROUP_PHOENIX_BETA = 'group:default/dpo-phoenix-beta';
export const AZURE_AD_GROUP_PN_DEVELOPER =
  'group:default/dpo-plugin-pn-developer';
export const AZURE_AD_GROUP_PN_MAINTAINER =
  'group:default/dpo-plugin-pn-maintainer';
export const AZURE_AD_GROUP_PN_OWNER = 'group:default/dpo-plugin-pn-owner';
export const AZURE_AD_GROUP_PN_TESTER = 'group:default/dpo-plugin-pn-tester';
export const AZURE_AD_GROUP_PN_CAMPAIGN_MAINTAINER =
  'group:default/dpo-plugin-pn-campaign-maintainer';
export const AZURE_AD_GROUP_IOT_DEV = 'group:default/dpo-plugin-iot-dev';
export const AZURE_AD_GROUP_IOT_STAGING =
  'group:default/dpo-plugin-iot-staging';
export const AZURE_AD_GROUP_IOT_PRODUCTION =
  'group:default/dpo-plugin-iot-production';
export const AZURE_AD_GROUP_IOT_PRODUCTION_EU =
  'group:default/dpo-plugin-iot-production-eu';
export const AZURE_AD_GROUP_IOT_PRODUCTION_APMEA =
  'group:default/dpo-plugin-iot-production-apmea';
export const AZURE_AD_GROUP_IOT_PRODUCTION_AMER =
  'group:default/dpo-plugin-iot-production-amer';
export const AZURE_AD_GROUP_ADP_TEAM = 'group:default/dpo-team-adp';
export const AZURE_AD_GROUP_ADP_MODULE = 'group:default/dpo-plugin-adp-module';
export const AZURE_AD_GROUP_ADP_PLATFORM =
  'group:default/dpo-plugin-adp-appliance-platform';

export const hasOwnership = (
  identity: BackstageUserIdentity | undefined,
  entity: string,
) => {
  // must be replaced by permission framework
  if (!identity) return false;
  const { ownershipEntityRefs } = identity;
  for (const entityRef of ownershipEntityRefs) {
    const regex = new RegExp(entity);
    if (regex.test(entityRef)) {
      return true;
    }
  }
  return false;
};

export const getPhoenixUser = () => {
  const identityApi = useApi(identityApiRef);
  const { value, loading, error } = useAsync(async () => {
    return {
      identity: await identityApi.getBackstageIdentity(),
      profile: await identityApi.getProfileInfo(),
    };
  }, []);

  if (loading || error) {
    return {
      identity: undefined,
      profile: {} as ProfileInfo,
      loading,
    };
  }

  return {
    identity: value!.identity,
    profile: value!.profile,
    loading,
  };
};

export * from './constants';
export * from './helpers';
