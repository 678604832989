import {
  IdentityApi,
  createApiRef,
  ConfigApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { TeamsApiOptions, Role, Permission } from './type';

import { ResponseError } from '@backstage/errors';

export const teamsApiRef = createApiRef<TeamsApi>({
  id: 'teams-api',
});

export class TeamsApi {
  private readonly fetchApi: FetchApi;
  private identityApi: IdentityApi;
  private configApi: ConfigApi;

  constructor(options: TeamsApiOptions) {
    this.identityApi = options.identityApi;
    this.configApi = options.configApi;
    this.fetchApi = options.fetchApi;
  }

  async addUserToGroup(
    groupUUID: string,
    userUUID: string | undefined,
  ): Promise<{
    message: string;
    status: string;
  }> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const requestUrl = `${backendUrl}/api/teams/group/user`;
    const requestBody = {
      groupUUID: groupUUID,
      userUUID: userUUID,
    };

    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  async removeUserFromGroup(
    groupUUID: string,
    userUUID: string | undefined,
  ): Promise<{
    status: string;
    message: string;
  }> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/teams/group/user`;
    const requestBody = {
      groupUUID: groupUUID,
      userUUID: userUUID,
    };
    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  async addOwnerToGroup(
    groupUUID: string,
    userUUID: string,
  ): Promise<{
    status: string;
    message: string;
  }> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/teams/group/owner`;

    const requestBody = {
      groupUUID: groupUUID,
      userUUID: userUUID,
    };

    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  async deleteUserFromGroupOwnerList(
    groupUUID: string,
    userUUID: string,
  ): Promise<{
    status: string;
    message: string;
  }> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/teams/group/owner`;
    const requestBody = {
      groupUUID: groupUUID,
      userUUID: userUUID,
    };
    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  async getGroupOwners(groupId: string): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/teams/group/owner?groupId=${groupId}`;
    const response = await this.fetchApi.fetch(requestUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  async registerUser(
    email: string,
    groupUUID: string,
  ): Promise<{
    message: string;
    status: string;
  }> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/teams/user/register`;
    const requestBody = {
      groupUUID: groupUUID,
      email: email,
    };

    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  async getGroupRoles(groupId: string): Promise<Role[]> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/teams/group/roles?groupId=${groupId}`;
    const response = await this.fetchApi.fetch(requestUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  async getGroupPermissions(groupId: string): Promise<Permission[]> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/teams/group/direct_permissions?groupId=${groupId}`;
    const response = await this.fetchApi.fetch(requestUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }
}
