import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';

export const applRegApiRef = createApiRef<ApplRegApi>({
  id: 'plugin.appliance-registration.service',
});

export type Options = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
  proxyPath?: string;
};

export class ApplRegApiClient implements ApplRegApi {
  // @ts-ignore
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async get(path: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }

    return await response.json();
  }

  // POST
  async post(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'POST',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }

    return await response.json();
  }

  // PUT
  async put(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'PUT',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }

    return await response.json();
  }

  // DELETE
  async delete(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'DELETE',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }

    return await response;
  }
}

export interface ApplRegApi {
  get(path: string): Promise<any>;
  post(path: string, payload?: { [key: string]: any }): Promise<any>;
  put(path: string, payload?: { [key: string]: any }): Promise<any>;
  delete(path: string, payload?: { [key: string]: any }): Promise<any>;
}
