import { z } from 'zod';
import { makeFieldSchemaFromZod } from '@backstage/plugin-scaffolder';

/**
 * @public
 */
export const IacPickerFieldSchema = makeFieldSchemaFromZod(
  z.string(),
  z.object({
    type: z.string().describe('Input label'),
    latest: z.boolean().optional().describe('Get latest version only'),
    helperText: z.string().optional().describe('Helper text to display'),
  }),
);

export const IacPickerSchema = IacPickerFieldSchema.schema;

export type IacPickerProps = typeof IacPickerFieldSchema.type;

/**
 * The input props that can be specified under `ui:options` for the
 * `OwnersPicker` field extension.
 *
 * @public
 */
export type IacPickerUiOptions = typeof IacPickerFieldSchema.uiOptionsType;
