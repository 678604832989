import { createPlugin, createRoutableExtension, createApiFactory, discoveryApiRef, identityApiRef } from '@backstage/core-plugin-api';
import { UserPageApiRef } from './api/UserPageApi';
import { UserPageClient } from './api/UserPageClient';
import { rootRouteRef } from './routes';

export const UserPlugin = createPlugin({
  id: 'user-page',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: UserPageApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new UserPageClient({ discoveryApi, identityApi }),
    }),
  ],
});

export const UserPage = UserPlugin.provide(
  createRoutableExtension({
    name: 'UserPage',
    component: () => import('./components/MainPage').then(m => m.MainPage),
    mountPoint: rootRouteRef,
  }),
);
