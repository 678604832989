import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { costExplorerApiRef } from './api/CostExplorerApi';
import { CostExplorerClient } from './api/CostExplorerClient';

export const costExplorerPlugin = createPlugin({
  id: 'cost-explorer',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: costExplorerApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new CostExplorerClient({ discoveryApi, identityApi }),
    }),
  ],
});

export const CostExplorerPage = costExplorerPlugin.provide(
  createRoutableExtension({
    name: 'CostExplorerPage',
    component: () => import('./components/Root').then(m => m.RootRoute),
    mountPoint: rootRouteRef,
  }),
);
