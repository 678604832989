import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { LabMateApi } from './LabMateApi';

export type Options = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
  proxyPath?: string;
};

export class LabMateApiClient implements LabMateApi {
  // @ts-ignore
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  // GET appliance
  async get(path: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('labmate')}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }

    const body = await response.json();
    return { status: response.status, body };
  }

  // Get email
  async getEmail(): Promise<any> {
    const jwt = require('jsonwebtoken');
    const { token: idToken } = await this.identityApi.getCredentials();
    const payload = jwt.decode(idToken);
    const email = payload.sub;
    return email;
  }

  // POST
  async post(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('labmate')}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken
        ? {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          }
        : {},
      method: 'POST',
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      const body = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${body}`;
      throw new Error(message);
    }
    const body = await response.json();
    return { status: response.status, body };
  }

  // PUT
  async put(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('labmate')}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'PUT',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }

    return await response.json();
  }

  // DELETE
  async delete(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('labmate')}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken
        ? {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          }
        : {},
      method: 'DELETE',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }

    return await response.json();
  }
}
