import React, { FC } from 'react';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';

import {
  Header,
  Page,
  Content,
  HeaderLabel,
  InfoCard,
} from '@backstage/core-components';

import TaskComponent from './TaskComponent';

function TabPanel(props: {
  children?: React.ReactNode;
  index: number;
  value: number;
}) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pl={3} pr={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export const TasksList: FC<{}> = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  return (
    <Page themeId="tool">
      <Header
        title="IDP Tasks"
        subtitle={
          <Typography variant="h5" color="textSecondary">
            Tasks
          </Typography>
        }
      >
        <HeaderLabel label="Owner" value="SRE" />
      </Header>
      <Content>
        <InfoCard title="IDP Tasks Viewer">
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            aria-label="Inspector options"
          >
            <Tab label="Regular" {...a11yProps(0)} />
            <Tab label="Scheduled" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            <TaskComponent />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <TaskComponent scheduled />
          </TabPanel>
        </InfoCard>
      </Content>
    </Page>
  );
};

export default TasksList;
