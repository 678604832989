import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { EntityFilterQuery } from '@backstage/catalog-client';
import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { EntitiesPickerProps } from './schema';

export { EntitiesPickerSchema } from './schema';

/**
 * The underlying component that is rendered in the form for the `EntitiesPicker`
 * field extension.
 *
 * @public
 */
export const EntitiesPicker = (props: EntitiesPickerProps) => {
  const { formData, onChange, uiSchema, required, rawErrors } = props;
  const catalogApi = useApi(catalogApiRef);
  const [entitiesOptions, setEntitiesOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState(false);
  const kind = (uiSchema['ui:options']?.kind as string) ?? 'Resource';
  const resource_type =
    (uiSchema['ui:options']?.resource_type as string) ?? 'aws_environment';
  const text = (uiSchema['ui:options']?.text as string) ?? 'Entities';
  const helperText = uiSchema['ui:options']?.helperText;

  const filter: EntityFilterQuery = {
    kind: [kind],
    'spec.type': resource_type,
  };

  const { loading } = useAsync(async () => {
    const { items } = await catalogApi.getEntities({
      filter: filter,
      fields: [
        'kind',
        'metadata.name',
        'metadata.annotations',
        'metadata.description',
        'spec.profile.displayName',
      ],
    });
    setEntitiesOptions(
      items.map(item => {
        return stringifyEntityRef(item);
      }),
    );
    return items;
  });

  const setTags = (_: React.ChangeEvent<{}>, values: string[] | null) => {
    // Reset error state in case all tags were removed
    const hasError = false;
    let addDuplicate = false;
    const currentEntities = formData || [];

    // If adding a new tag
    if (values?.length && currentEntities.length < values.length) {
      const newTag = (values[values.length - 1] = values[values.length - 1]
        .toLocaleLowerCase('en-US')
        .trim());
      // hasError = !entityValidator(newTag);
      addDuplicate = currentEntities.indexOf(newTag) !== -1;
    }

    setInputError(hasError);
    setInputValue(!hasError ? '' : inputValue);
    if (!hasError && !addDuplicate) {
      onChange(values || []);
    }
  };

  // Initialize field to always return an array
  useEffectOnce(() => onChange(formData || []));

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        multiple
        freeSolo
        filterSelectedOptions
        onChange={setTags}
        value={formData || []}
        inputValue={inputValue}
        loading={loading}
        options={entitiesOptions}
        ChipProps={{ size: 'small' }}
        renderInput={params => (
          <TextField
            {...params}
            label={text}
            onChange={e => setInputValue(e.target.value)}
            error={inputError}
            required={required}
            helperText={
              helperText ??
              `Add ${kind.toLocaleLowerCase()}s who will own this resource`
            }
          />
        )}
      />
    </FormControl>
  );
};
