import { OcpAppliancePageApi } from './OcpAppliancePageApi';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import {
  ApplianceStateResponse,
  ApplianceCapabilitiesResponse,
  CommandResponse,
  WhitelistedPropertiesResponse,
  SearchAppliancesIdentityInfoByIdPartsResponse,
  UsersResponse,
  ConfigResponse,
  ConnectivityEventsResponse,
  SerializationResponse,
} from './types';
import { BASE_URL, ENV, REGION, getECPBaseUrl } from '../config';
import { buildUrl } from '../utils';
/** @public */
export class OcpAppliancePageClient implements OcpAppliancePageApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async getApplianceState(
    applianceId: string,
  ): Promise<ApplianceStateResponse> {
    const url = buildUrl(BASE_URL, {
      path: `${applianceId}/all-shadows`,
    });
    return await this.get(url);
  }

  async getECPApplianceState(
    applianceId: string,
  ): Promise<ApplianceStateResponse> {
    const url = buildUrl(getECPBaseUrl(ENV, REGION), {
      path: `${applianceId}/state`,
    });
    return await this.get(url);
  }

  async getApplianceCapabilities(
    applianceId: string,
  ): Promise<ApplianceCapabilitiesResponse> {
    const url = buildUrl(BASE_URL, {
      path: `${applianceId}/capabilities`,
    });
    return await this.get(url);
  }

  async getApplianceCommands(
    applianceId: string,
    limit: number,
  ): Promise<CommandResponse> {
    const url = buildUrl(BASE_URL, {
      path: `${applianceId}/commands`,
      queryParams: {
        limit: limit.toString(),
      },
    });
    return await this.get(url);
  }

  async getWhitelistedProperties(
    applianceType: string,
  ): Promise<WhitelistedPropertiesResponse> {
    const url = buildUrl(BASE_URL, {
      path: `/properties/mapper/${applianceType}`,
    });
    return await this.get(url);
  }

  async searchAppliancesIdentityInfoByIdParts(
    pnc: string,
    elc: string,
    sn: string,
    mac: string,
  ): Promise<SearchAppliancesIdentityInfoByIdPartsResponse> {
    const url = buildUrl(BASE_URL, {
      path: `/search?pnc=${pnc}&elc=${elc}&sn=${sn}&mac=${mac}`,
    });
    return await this.get(url);
  }

  async getUsers(applianceId: string): Promise<UsersResponse> {
    const response = await this.get(
      buildUrl(BASE_URL, {
        path: `${applianceId}/users`,
      }),
    );
    return {
      users: response.body,
      status: response.status,
    };
  }

  async getConfig(applianceId: string): Promise<ConfigResponse> {
    const response = await this.get(
      buildUrl(BASE_URL, {
        path: `${applianceId}/config`,
      }),
    );
    return {
      config: response.body,
      status: response.status,
    };
  }

  async getConnectivityEvents(
    applianceId: string,
    limit: number,
  ): Promise<ConnectivityEventsResponse> {
    const response = await this.get(
      buildUrl(BASE_URL, {
        path: `${applianceId}/connectivity-events`,
        queryParams: limit !== -1 ? { limit } : {},
      }),
    );
    return {
      events: response.body,
      status: response.status,
    };
  }

  async getSerializationStatus(
    applianceId: string,
  ): Promise<SerializationResponse> {
    const response = await this.get(
      buildUrl(BASE_URL, {
        path: `${applianceId}/serialization`,
      }),
    );
    return {
      serialized: response.body.applianceCertificateExist,
      status: response.status,
    };
  }

  async get(path: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    if (!response.ok) {
      if (response.status === 404 || response.status === 500) {
        return { status: response.status, body: null };
      }
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }

    const body = await response.json();
    return { status: response.status, body };
  }

  async post(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'POST',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const body = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${body}`;
      throw new Error(message);
    }
    const body = await response.json();
    return { status: response.status, body };
  }
}
