import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { LabMateApiClient, labMateApiRef } from './api';

export const labmatePlugin = createPlugin({
  id: 'labmate',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: labMateApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new LabMateApiClient({ discoveryApi, identityApi }),
    }),
  ],
});

export const LabmatePage = labmatePlugin.provide(
  createRoutableExtension({
    name: 'LabmatePage',
    component: () => import('./components/Home').then(m => m.Home),
    mountPoint: rootRouteRef,
  }),
);
