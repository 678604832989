import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'adp',
});

export const pdclSubRouteRef = createSubRouteRef({
  path: '/adp/pdcl',
  id: 'adp-pdcl',
  parent: rootRouteRef,
});

export const pdclTabSubRouteRef = createSubRouteRef({
  path: '/adp/pdcl/:tab',
  id: 'adp-pdcl-tab',
  parent: rootRouteRef,
});

export const moduleSubRouteRef = createSubRouteRef({
  path: '/adp/module',
  id: 'adp-module',
  parent: rootRouteRef,
});

export const platformSubRouteRef = createSubRouteRef({
  path: '/adp/platform',
  id: 'adp-platform',
  parent: rootRouteRef,
});
