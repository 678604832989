import {
  Checkpoint,
  DeliverableAction,
  PDCLDeliverable,
  PDCLDeliverableStatus,
} from '../dto/pdcl-deliverable.types';
import {
  BA,
  Brand,
  ProductCategory,
  ProductSubCategory,
} from '../dto/pdcl-project.types';
import {
  INVALID_INPUT,
  brandSchema,
  businessAreaSchema,
  checkpointActionSchema,
  checkpointSchema,
  consumerExperienceSchema,
  deliverableStatusSchema,
  pdclDeliverableSchema,
  productSubCategorySchema,
} from '../validation/pdcl.validation';
import { PDCLProjectData } from './types/pdcl.project.types';

export function getPDCLDeliverables(
  data: PDCLProjectData[],
  pdclData: PDCLDeliverable[],
) {
  data.forEach(project => {
    project.deliverables.forEach(deliverable => {
      let responsible = '';
      deliverable.checkpointDetails.forEach(checkpointDetail => {
        // Set consumer experience of deliverable
        let consumerExperience = project.consumerExperience;
        try {
          consumerExperienceSchema.parse(consumerExperience);
        } catch (error) {
          consumerExperience = INVALID_INPUT;
        }

        // Set product subcategory
        let productSubCategory = project.productSubCategory;
        try {
          productSubCategorySchema.parse(productSubCategory);
        } catch (error) {
          productSubCategory = INVALID_INPUT;
        }

        // Set business area
        let businessArea = project.businessArea
          .split(/[,&]/g)
          .map((businessAreaItem: string) => businessAreaItem.trim());
        try {
          businessAreaSchema.parse(businessArea);
        } catch (error) {
          businessArea = [INVALID_INPUT];
        }

        // Set brand
        let brand = project.brand
          .split(/[,&]/g)
          .map((brandItem: string) => brandItem.trim());
        try {
          brandSchema.parse(brand);
        } catch (error) {
          brand = [INVALID_INPUT];
        }

        // Set checkpoint of deliverable
        let checkpoint = checkpointDetail.checkpoint;
        try {
          checkpointSchema.parse(checkpoint);
        } catch (error) {
          checkpoint = INVALID_INPUT;
        }

        // Set action of deliverable in checkpoint
        let action = checkpointDetail.action;
        try {
          checkpointActionSchema.parse(action);
        } catch (error) {
          action = INVALID_INPUT;
        }

        // Set responsible of deliverable
        if (checkpointDetail.responsible !== undefined) {
          responsible = checkpointDetail.responsible as string;
        }

        // Set status of deliverable in checkpoint
        let checkPointStatus =
          checkpointDetail.status[0].toLocaleUpperCase('en-US') +
          checkpointDetail.status.substring(1).toLocaleLowerCase('en-US');

        try {
          deliverableStatusSchema.parse(checkPointStatus);
        } catch (error) {
          checkPointStatus = INVALID_INPUT;
        }

        const pdclDataRow: PDCLDeliverable = {
          key: deliverable.id,
          project: {
            id: project.id,
            name: project.name,
            consumerExperience: consumerExperience as ProductCategory,
            productSubCategory: productSubCategory as ProductSubCategory,
            businessArea: businessArea as BA,
            brand: brand as Brand,
            url: project.url,
          },
          checkpoint: checkpoint as Checkpoint,
          deliverable: deliverable.name,
          action: action as DeliverableAction,
          responsible: checkpointDetail.responsible
            ? checkpointDetail.responsible
            : responsible,
          status: checkPointStatus as PDCLDeliverableStatus,
          version: checkpointDetail.version,
          url: deliverable.url,
        };

        try {
          pdclDeliverableSchema.parse(pdclDataRow);
          pdclDataRow.deliverable = deliverable.name.split(']')[1].substring(1);
          pdclData.push(pdclDataRow);
        } catch (error) {
          pdclDataRow.deliverable = INVALID_INPUT;
          pdclData.push(pdclDataRow);
        }
      });
    });
  });

  return pdclData;
}
