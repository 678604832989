export let REGION = 'apac';
export let ENV = 'prod';
export const PAGE = '/user-page';
export const PATH = 'api/v1/appliances';
export let BASE_URL = `${PAGE}/${ENV}-${REGION}/${PATH}`;
export const AZURE_AD_GROUP_IOT_DEV = 'group:default/dpo-plugin-iot-dev';
export const AZURE_AD_GROUP_IOT_STAGING =
  'group:default/dpo-plugin-iot-staging';
export const AZURE_AD_GROUP_IOT_PRODUCTION =
  'group:default/dpo-plugin-iot-production';
export const AZURE_AD_GROUP_IOT_PRODUCTION_EU =
  'group:default/dpo-plugin-iot-production-eu';
export const AZURE_AD_GROUP_IOT_PRODUCTION_APMEA =
  'group:default/dpo-plugin-iot-production-apmea';
export const AZURE_AD_GROUP_IOT_PRODUCTION_AMER =
  'group:default/dpo-plugin-iot-production-amer';

export const modifyRegionAndEnv = (env: string, region: string) => {
  ENV = env;
  REGION = region;
  BASE_URL = `${PAGE}/${ENV}-${REGION}/${PATH}`;
};

export const logsUrl = (
  env: string,
  region: string,
  applianceId: string,
): string => {
  return `https://console.cloud.google.com/logs/query;query=jsonPayload.applianceId%20%3D%20%22${applianceId}%22%20OR%20jsonPayload.message%20:%20%22${applianceId}%22;timeRange=P2D?project=${env}-${region}-135790`;
};
