import { Entity } from '@backstage/catalog-model';
import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  createComponentExtension,
  createRouteRef,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { IdpApiClient, idpApiRef } from './api';
import {
  IDP_RESOURCE_ID_ANNOTATION,
  IDP_UI_TABS_ANNOTATION,
  IDP_COMPONENT_ID_ANNOTATION,
  IDP_PROJECT_ID_ANNOTATION,
  IDP_ORGANIZATION_ID_ANNOTATION,
} from '@internal/plugin-catalog-module-common-idp';

export const isIdpMetadataAvailable = (entity: Entity) =>
  Boolean(
    entity?.metadata.annotations?.[IDP_UI_TABS_ANNOTATION]
      ? entity?.metadata.annotations?.[IDP_UI_TABS_ANNOTATION].split(
          ',',
        ).includes('metadata')
      : false,
  );

export const isIdpRecentCostAvailable = (entity: Entity) =>
  Boolean(
    entity?.metadata.annotations?.[IDP_UI_TABS_ANNOTATION]
      ? entity?.metadata.annotations?.[IDP_UI_TABS_ANNOTATION].split(
          ',',
        ).includes('cost')
      : false,
  );

export const isIdpResource = (entity: Entity) =>
  Boolean(
    entity?.metadata.annotations?.[IDP_RESOURCE_ID_ANNOTATION] ? true : false,
  );

export const isIdpService = (entity: Entity) =>
  Boolean(
    entity?.metadata.annotations?.[IDP_COMPONENT_ID_ANNOTATION] ? true : false,
  );

export const isIdpProject = (entity: Entity) =>
  Boolean(
    entity?.metadata.annotations?.[IDP_PROJECT_ID_ANNOTATION] ? true : false,
  );

export const isIdpOrganization = (entity: Entity) =>
  Boolean(
    entity?.metadata.annotations?.[IDP_ORGANIZATION_ID_ANNOTATION]
      ? true
      : false,
  );

export const hasIdpExecutor = (entity: Entity) =>
  Boolean(entity?.metadata.tags?.includes('terraform'));

export const entityContentRouteRef = createRouteRef({
  id: 'Idp Entity Content',
});

export const idpPlugin = createPlugin({
  id: 'idp-frontend',
  apis: [
    createApiFactory({
      api: idpApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new IdpApiClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    entityContent: entityContentRouteRef,
  },
});

export const EntityIdpMetadataContent = idpPlugin.provide(
  createRoutableExtension({
    name: 'EntityIdpMetadataContent',
    component: () =>
      import('./components/IdpMetadataPage').then(m => m.IdpMetadataPage),
    mountPoint: entityContentRouteRef,
  }),
);

export const EntityIdpVaultContent = idpPlugin.provide(
  createRoutableExtension({
    name: 'EntityIdpVaultContent',
    component: () =>
      import('./components/IdpVaultPage').then(m => m.IdpVaultPage),
    mountPoint: entityContentRouteRef,
  }),
);

export const EntityIdpMetricsContent = idpPlugin.provide(
  createRoutableExtension({
    name: 'EntityIdpMetricsContent',
    component: () =>
      import('./components/IdpMetricsPage').then(m => m.IdpMetricsPage),
    mountPoint: entityContentRouteRef,
  }),
);

export const EntityIdpRecentlyCostContent = idpPlugin.provide(
  createRoutableExtension({
    name: 'EntityIdpRecentlyCostContent',
    component: () =>
      import('./components/IdpRecentCostGraphPage').then(
        m => m.IdpRecentCostGraphPage,
      ),
    mountPoint: entityContentRouteRef,
  }),
);

export const EntityIdpRecentlyCostCard = idpPlugin.provide(
  createComponentExtension({
    name: 'EntityIdpRecentlyCostCard',
    component: {
      lazy: () =>
        import('./components/RecentCostGraphWidget').then(
          m => m.RecentCostGraphWidget,
        ),
    },
  }),
);

export const EntityIdpExecutorListCard = idpPlugin.provide(
  createComponentExtension({
    name: 'EntityIdpExecutorListCard',
    component: {
      lazy: () =>
        import('./components/ExecutorComponents/ExecutorListWidget').then(
          m => m.ExecutorListhWidget,
        ),
    },
  }),
);

export const EntityIdpDependenciesListCard = idpPlugin.provide(
  createComponentExtension({
    name: 'EntityIdpDependenciesListCard',
    component: {
      lazy: () =>
        import(
          './components/DependenciesComponents/DependenciesListWidget'
        ).then(m => m.DependenciesListPagehWidget),
    },
  }),
);

export const EntityIdpEcrContent = idpPlugin.provide(
  createRoutableExtension({
    name: 'EntityIdpEcrContent',
    component: () => import('./components/IdpEcrPage').then(m => m.IdpEcrPage),
    mountPoint: entityContentRouteRef,
  }),
);

export const EntityIdpRevisionContent = idpPlugin.provide(
  createRoutableExtension({
    name: 'EntityIdpRevisionContent',
    component: () =>
      import('./components/IdpRevisionPage').then(m => m.IdpRevisionPage),
    mountPoint: entityContentRouteRef,
  }),
);

export const EntityIdpLogContent = idpPlugin.provide(
  createRoutableExtension({
    name: 'EntityIdpLogContent',
    component: () => import('./components/IdpLogPage').then(m => m.IdpLogPage),
    mountPoint: entityContentRouteRef,
  }),
);

export const EntityIdpAboutCard = idpPlugin.provide(
  createRoutableExtension({
    name: 'EntityIdpAboutCard',
    component: () =>
      import('./components/IdpAboutCard').then(m => m.IdpAboutCard),
    mountPoint: entityContentRouteRef,
  }),
);

export const EntityIdpAboutServiceCard = idpPlugin.provide(
  createRoutableExtension({
    name: 'EntityIdpAboutServiceCard',
    component: () =>
      import('./components/IdpAboutCard').then(m => m.IdpAboutServiceCard),
    mountPoint: entityContentRouteRef,
  }),
);

export const EntityIdpKubernetesContent = idpPlugin.provide(
  createRoutableExtension({
    name: 'EntityIdpKubernetesContent',
    component: () =>
      import('./components/IdpKubernetesPage').then(m => m.IdpKubernetesPage),
    mountPoint: entityContentRouteRef,
  }),
);

export const EntityIdpTreeCard = idpPlugin.provide(
  createComponentExtension({
    name: 'EntityIdpRecentlyCostCard',
    component: {
      lazy: () =>
        import('./components/IdpTreeView/TreeWidget').then(m => m.TreeWidget),
    },
  }),
);
