import {
  IdentityApi,
  createApiRef,
  ConfigApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { SlackApiOptions } from './type';
import { ResponseError } from '@backstage/errors';

export const slackApiRef = createApiRef<SlackApi>({
  id: 'slack',
});

/**
 * The Slack API provides information about slack
 *
 * @public
 */
export type SlackApi = {
  /**
   * Get slack user
   */
  getSlackUser(email?: string): Promise<any>;
};

export class SlackApiClient implements SlackApi {
  private readonly fetchApi: FetchApi;
  private identityApi: IdentityApi;
  private configApi: ConfigApi;

  constructor(options: SlackApiOptions) {
    this.identityApi = options.identityApi;
    this.configApi = options.configApi;
    this.fetchApi = options.fetchApi;
  }

  async getSlackUser(emails?: string | string[]) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    let requestUrl;
    if (Array.isArray(emails)) {
      requestUrl = `${backendUrl}/api/slack/user?emails=${emails.join(',')}`;
    } else if (emails) {
      requestUrl = `${backendUrl}/api/slack/user?emails=${emails}`;
    } else {
      requestUrl = `${backendUrl}/api/slack/user`;
    }

    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }
}
