// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, TextField, List, ListItem } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';

export default class KeyValue extends React.Component {
  static displayName = 'KeyValue';

  static propTypes = {
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        keyItem: PropTypes.string,
        valueItem: PropTypes.string,
      }),
    ),
    onChange: PropTypes.func,
    blacklistedKeys: PropTypes.arrayOf(PropTypes.string),
    keyInputPlaceholder: PropTypes.string,
    valueInputPlaceholder: PropTypes.string,
    hideLabels: PropTypes.bool,
  };

  static defaultProps = {
    rows: [],
    onChange: () => {},
    keyInputPlaceholder: '',
    valueInputPlaceholder: '',
    blacklistedKeys: [],
    hideLabels: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      rows: [...this.props.rows],
    };
  }

  handleAddNew = () => {
    this.setState(
      {
        rows: [
          ...this.state.rows,
          {
            keyItem: '',
            valueItem: '',
          },
        ],
      },
      () => {
        this.props.onChange([...this.state.rows]);
      },
    );
  };

  handleKeyItemChange(index, value) {
    this.setState(
      {
        rows: this.state.rows.map((row, i) => {
          if (index !== i) {
            return row;
          }
          if (
            this.props.blacklistedKeys.includes(
              value.toLocaleLowerCase('en-US'),
            )
          ) {
            return {
              ...row,
              keyItem: value,
              error: 'This name is reserved',
            };
          }
          if (row.error) {
            delete row.error;
          }
          return {
            ...row,
            keyItem: value,
          };
        }),
      },
      () => {
        this.props.onChange([...this.state.rows]);
      },
    );
  }

  handleValueItemChange(index, value) {
    this.setState(
      {
        rows: this.state.rows.map((row, i) => {
          if (index !== i) {
            return row;
          }
          return {
            ...row,
            valueItem: value,
          };
        }),
      },
      () => {
        this.props.onChange([...this.state.rows]);
      },
    );
  }

  handleRemove(index) {
    this.setState(
      {
        rows: this.state.rows.filter((row, i) => i !== index),
      },
      () => {
        this.props.onChange([...this.state.rows]);
      },
    );
  }

  toJSON() {
    const { rows = [] } = this.state;
    return rows.reduce((acc, row) => {
      acc[row.keyItem] = row.valueItem;
      return acc;
    }, {});
  }

  renderKeyItem(index, value) {
    return (
      <>
        <TextField
          id={`kIndex${index}`}
          label="Key"
          type="text"
          value={value.keyItem}
          error={value.error ? true : false}
          helperText={value.error ? value.error : ''}
          placeholder={this.props.keyInputPlaceholder}
          onChange={e => this.handleKeyItemChange(index, e.currentTarget.value)}
        />
      </>
    );
  }

  renderValueItem(index, value) {
    return (
      <>
        <TextField
          type="text"
          label="Value"
          id={`vIndex${index}`}
          value={value}
          placeholder={this.props.valueInputPlaceholder}
          onChange={e =>
            this.handleValueItemChange(index, e.currentTarget.value)
          }
        />
      </>
    );
  }

  renderRows() {
    return (
      <List>
        {this.state.rows.map((row, i) => (
          <ListItem key={i}>
            {this.renderKeyItem(i, row)}
            {this.renderValueItem(i, row.valueItem)}
            <IconButton title="Delete" onClick={() => this.handleRemove(i)}>
              <DeleteForeverIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    );
  }

  renderAddButton() {
    return (
      <IconButton title="Delete" onClick={this.handleAddNew}>
        <AddIcon />
      </IconButton>
    );
  }

  render() {
    return (
      <>
        {this.renderRows()}
        {this.renderAddButton()}
      </>
    );
  }
}
