import { createApiRef } from '@backstage/core-plugin-api';

import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { WaitingListBucket, WaitingListEntry } from './types';

/** @public */
export const waitingListApiRef = createApiRef<WaitingListApi>({
  id: 'plugin.waiting-list.service',
});

/** @public */
export class WaitingListClient implements WaitingListApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async getBucketList(): Promise<WaitingListBucket[]> {
    return await this.get(`/waiting_list`);
  }

  async getBucket(entity: string): Promise<WaitingListBucket> {
    return await this.get(`/waiting_list/${entity}`);
  }

  async get(path: string, format?: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/fdn-waiting-list-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }

    if (format && format === 'text') {
      return response.text();
    }
    return await response.json();
  }

  // POST
  async createBucket(payload: {
    [key: string]: any;
  }): Promise<WaitingListBucket> {
    return await this.post(`/waiting_list`, payload);
  }

  async createBucketEntry(
    bucket_id: string,
    payload: { [key: string]: any },
  ): Promise<WaitingListEntry> {
    return await this.post(`/waiting_list/${bucket_id}/entry`, payload);
  }

  async post(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/fdn-waiting-list-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'POST',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }

    return await response.json();
  }

  // PUT
  async updateBucketEntry(
    bucket_id: string,
    entry_id: string,
    payload: { [key: string]: any },
  ): Promise<WaitingListEntry> {
    return await this.put(
      `/waiting_list/${bucket_id}/entry/${entry_id}`,
      payload,
    );
  }

  async put(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/fdn-waiting-list-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'PUT',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }

    return await response.json();
  }

  // DELETE
  async deleteBucketEntry(bucket_id: string, entry_id: string): Promise<any> {
    return await this.delete(
      `/waiting_list/${bucket_id}/entry/${entry_id}`,
      {},
    );
  }

  async delete(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/fdn-waiting-list-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'DELETE',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }

    return await response.text();
  }
}

/** @public */
export interface WaitingListApi {
  get(path: string, format?: string): Promise<any>;
  post(path: string, payload: { [key: string]: any }): Promise<any>;
  put(path: string, payload: { [key: string]: any }): Promise<any>;
  delete(path: string, payload: { [key: string]: any }): Promise<any>;
  getBucketList(): Promise<WaitingListBucket[]>;
  getBucket(entity: string): Promise<WaitingListBucket>;
  createBucket(payload: { [key: string]: any }): Promise<WaitingListBucket>;
  createBucketEntry(
    bucket_id: string,
    payload: { [key: string]: any },
  ): Promise<WaitingListEntry>;
  updateBucketEntry(
    bucket_id: string,
    entry_id: string,
    payload: { [key: string]: any },
  ): Promise<WaitingListEntry>;
  deleteBucketEntry(bucket_id: string, entry_id: string): Promise<any>;
}
