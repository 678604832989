import { DocumentationCard } from '../dto/documentation.types';
import { Document } from './types/document.types';

function htmlDecode(input: string) {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent as string;
}

function removeNumbersFromTitle(input: string) {
  const title = input.split('.');
  return title[title.length - 1][0] === ' '
    ? title[title.length - 1].substring(1)
    : title[title.length - 1];
}

function toTitleCase(input: string) {
  return input[0].toLocaleUpperCase('en-US') + input.slice(1);
}

export function getConfluencePages(
  nestedExpression: Document,
  out: DocumentationCard[],
  titleLabelPrefix: string,
) {
  if (!nestedExpression.parents) nestedExpression.parents = [];

  nestedExpression.children.forEach(child => {
    child.parents = [];
    nestedExpression.parents.forEach(parent => child.parents.push(parent));
    if (
      titleLabelPrefix &&
      nestedExpression.labels.some(label => label.includes(titleLabelPrefix))
    ) {
      const newtitle = nestedExpression.labels
        .filter(label => label.includes(titleLabelPrefix))[0]
        .split('=')[1];
      child.parents.push(toTitleCase(newtitle));
    }

    if (!child.children.length) {
      out.push({
        key: child.id,
        title: removeNumbersFromTitle(child.title),
        description: htmlDecode(child.description),
        link: child.url,
        subtitle: child.parents[child.parents.length - 1],
        types: child.parents,
        labels: child.labels,
      });
    }
    return getConfluencePages(child, out, titleLabelPrefix);
  });
  return out;
}
