import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { HaclEventsCountPageApiRef, HaclEventsCountPageClient } from './api';

export const haclEventsCountPlugin = createPlugin({
  id: 'hacl-events-count',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: HaclEventsCountPageApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new HaclEventsCountPageClient({ discoveryApi, identityApi }),
    }),
  ],
});

export const HaclEventsCountPage = haclEventsCountPlugin.provide(
  createRoutableExtension({
    name: 'HaclEventsCountPage',
    component: () => import('./components/MainPage').then(m => m.MainPage),
    mountPoint: rootRouteRef,
  }),
);
