import { z } from 'zod';
import { makeFieldSchemaFromZod } from '@backstage/plugin-scaffolder';

/**
 * @public
 */
export const OwnersPickerFieldSchema = makeFieldSchemaFromZod(
  z.array(z.string()),
  z.object({
    kinds: z
      .array(z.string())
      .optional()
      .describe('List of kinds of entities to derive tags from'),
    group_type: z.string().optional().describe('Filter by group type'),
    helperText: z.string().optional().describe('Helper text to display'),
  }),
);

export const OwnersPickerSchema = OwnersPickerFieldSchema.schema;

export type OwnersPickerProps = typeof OwnersPickerFieldSchema.type;

/**
 * The input props that can be specified under `ui:options` for the
 * `OwnersPicker` field extension.
 *
 * @public
 */
export type OwnersPickerUiOptions =
  typeof OwnersPickerFieldSchema.uiOptionsType;
