// @ts-nocheck
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { StatusPageApi } from './StatusPageApi';

export class StatusPageApiClient implements StatusPageApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  regionsCache: [];
  regionIdToNames: Map<number, string>;
  environmentIdToNames: Map<number, string>;
  environmentsCache: [];
  componentsCache: [];
  componentsIdToNames: Map<number, string>;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
    this.regionIdToNames = new Map();
    this.environmentIdToNames = new Map();
    this.regionsCache = [];
    this.environmentsCache = [];
    this.componentsCache = [];
    this.componentsIdToNames = new Map();
  }

  async getRegions(): Promise<any> {
    if (this.regionsCache.length) {
      return this.regionsCache;
    }

    const resp = {
      regions: [
        {
          color: '#F50505',
          human_name: 'AMER',
          id: '62cc14cec6b7dd776437e92d',
          name: 'AMER',
        },
        {
          color: '#A9B91C',
          human_name: 'APAC',
          id: '62cc14cec6b7dd776437e92b',
          name: 'APAC',
        },
        {
          color: '#2613E2',
          human_name: 'EMEA',
          id: '62cc14cec6b7dd776437e92c',
          name: 'EMEA',
        },
      ],
    };
    this.regionsCache = resp.regions;
    this.regionsCache.forEach(region => {
      this.regionIdToNames.set(region.id, region.human_name);
    });
    return this.regionsCache;
  }

  async getEnvironments(): Promise<any> {
    if (this.environmentsCache.length) {
      return this.environmentsCache;
    }

    const resp = {
      environments: [
        {
          color: '#BCE213',
          human_name: 'Staging',
          id: '62cc14a231eaeb4a5f672131',
          name: 'STAGING',
        },
        {
          color: '#13D2E2',
          human_name: 'Test',
          id: '62cc14a131eaeb4a5f67212e',
          name: 'TEST',
        },
        {
          color: '#FF5733',
          human_name: 'Production',
          id: '62cc14a131eaeb4a5f67212f',
          name: 'PROD',
        },
        {
          color: '#D5E213',
          human_name: 'Pre-production',
          id: '62cc14a231eaeb4a5f672130',
          name: 'UAT',
        },
      ],
    };
    // only keep Pre-production and Production
    resp.environments.forEach(env => {
      if (
        env.human_name.toUpperCase() === 'PRE-PRODUCTION' ||
        env.human_name.toUpperCase() === 'PRODUCTION'
      ) {
        this.environmentsCache.push(env);
        this.environmentIdToNames.set(env.id, env.human_name);
      }
    });
    return this.environmentsCache;
  }

  async getDatadogMetrics(query: string): Promise<any> {
    return await this.get(encodeURI(`/datadog_metrics/${query}`));
  }

  private async get(path: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/phoenix-idp-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }

    return await response.json();
  }
}
