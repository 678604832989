import { createApiRef } from '@backstage/core-plugin-api';

export type CfRouterApi = {
  get(path: string): Promise<any>;
  download(path: string): Promise<any>;
  downloadCapabilities(applianceId: string, env: string): Promise<any>;
};

export const cfRouterApiRef = createApiRef<CfRouterApi>({
  id: 'plugin.cf-router.service',
});
