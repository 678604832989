import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';

export const orgChartApiRef = createApiRef<OrgChartApi>({
  id: 'plugin.org-chart.service',
});

export type Options = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
};

export interface OrgChartApi {
  getOrgChart(): Promise<any>;
  getTeamsChart(): Promise<any>;
  getSsoPermissionSets(haAccount: string): Promise<any>;
  getSsoUsersPermissions(accountId: string): Promise<any>;
  getCurrentUser(email?: string): Promise<any>;
  getAllTeams(): Promise<any>;
  addTeam(teamName: string, owner: string): Promise<any>;
  addSsoPermissionSet(haAccount: string, accountId: string): Promise<any>;
  isAccountOwner(accountId: string): Promise<any>;
  removeSsoPermissionSet(
    haAccount: string,
    accountId: string,
    permission: string,
  ): Promise<any>;
}

export class OrgChartApiClient implements OrgChartApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async getOrgChart(): Promise<any> {
    return await this.get('/org');
  }

  async getTeamsChart(): Promise<any> {
    return await this.get('/teams');
  }

  async getSsoPermissionSets(haAccount: string): Promise<any> {
    return await this.fetchSsoHaAccount(haAccount, '/aws/sso');
  }

  async getSsoUsersPermissions(accountId: string): Promise<any> {
    return await this.fetchSsoUsersPermissions(
      accountId,
      '/aws/sso/account/permissions',
    );
  }

  private async fetchSsoHaAccount(
    haAccount: string,
    path: string,
  ): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'org-chart',
    )}${path}?ha_account=${haAccount}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });
    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }
    return await response.json();
  }

  private async fetchSsoUsersPermissions(
    accountId: string,
    path: string,
  ): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'org-chart',
    )}${path}?account_id=${accountId}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });
    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }
    return await response.json();
  }

  async addSsoPermissionSet(
    haAccount: string,
    accountId: string,
  ): Promise<any> {
    const payload = {
      ha_account: haAccount,
      account_id: accountId,
      action: 'add_permission',
    };
    return await this.post('/aws/sso/account/permissions', payload);
  }

  async removeSsoPermissionSet(
    haAccount: string,
    accountId: string,
    permission: string,
  ): Promise<any> {
    const payload = {
      ha_account: haAccount,
      account_id: accountId,
      permission: permission,
      action: 'remove_permission',
    };
    return await this.post('/aws/sso/account/permissions', payload);
  }

  async isAccountOwner(accountId: string): Promise<any> {
    return await this.get(`/aws/sso/account/owners?account_id=${accountId}`);
  }

  async getAllTeams(): Promise<any> {
    return await this.get('/groups');
  }

  async getCurrentUser(email?: string): Promise<any> {
    const path = encodeURI(`/current-user?email=${email}`);
    return await this.get(path);
  }

  async addTeam(teamName: string, owner: string): Promise<any> {
    const payload = {
      name: teamName,
      owner: owner,
    };
    return await this.post('/add-team', payload);
  }

  private async get(path: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('org-chart')}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }
    return await response.json();
  }

  private async post(
    path: string,
    payload?: Record<string, any>,
  ): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('org-chart')}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const reqHeaders = new Headers();
    if (idToken) {
      reqHeaders.set('Authorization', `Bearer ${idToken}`);
    }
    reqHeaders.set('Content-Type', 'Application/json');
    const response = await fetch(url, {
      method: 'POST',
      headers: reqHeaders,
      body: JSON.stringify(payload),
    });

    return await response.json();
  }
}
