import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import { useApi } from '@backstage/core-plugin-api';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import { IacPickerProps } from './schema';

export { IacPickerSchema } from './schema';
import { IacVersion, idpApiRef } from '../../api';

function stringifyIac(item: IacVersion) {
  if (!item.value) return '';
  return `${item.value.toLocaleLowerCase('en-US')} ${item.description}`;
}
/**
 * The underlying component that is rendered in the form for the `IacPicker`
 * field extension.
 *
 * @public
 */
export const IacPicker = (props: IacPickerProps) => {
  const { formData, onChange, uiSchema, required, rawErrors } = props;
  const idpApi = useApi(idpApiRef);
  const [iacOptions, setIacOptions] = useState<IacVersion[]>([]);
  const resource_type = uiSchema['ui:options']?.type as string;
  const latest = uiSchema['ui:options']?.latest ?? false;
  const helperText =
    uiSchema['ui:options']?.helperText ?? 'Infrustructure code version';

  const { loading } = useAsync(async () => {
    const items: IacVersion[] = await idpApi.getSemlaTags({
      component: resource_type,
      latest: `${latest}`,
    });
    onChange(items.map(item => item.value)[0]);
    setIacOptions(items);
    return items;
  });
  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    onChange(event.target.value as string);
  };

  // Initialize field to always return an array
  useEffectOnce(() => onChange(formData || ''));

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <InputLabel>IaC</InputLabel>
      {!loading ? (
        <Select onChange={handleChange} value={formData}>
          {iacOptions?.map(e => (
            <MenuItem key={e.value} value={e.value}>
              {stringifyIac(e)}
            </MenuItem>
          ))}
        </Select>
      ) : null}
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
