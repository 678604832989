import {
  IdentityApi,
  createApiRef,
  ConfigApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { ToolsApiOptions } from './type';
import { ResponseError } from '@backstage/errors';

export const toolsApiRef = createApiRef<ToolsApi>({
  id: 'tools-api',
});

export class ToolsApi {
  private readonly fetchApi: FetchApi;
  private identityApi: IdentityApi;
  private configApi: ConfigApi;

  constructor(options: ToolsApiOptions) {
    this.identityApi = options.identityApi;
    this.configApi = options.configApi;
    this.fetchApi = options.fetchApi;
  }

  async getAtlassianUser(userEntityRef: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/toolset/atlassian/user?userEntityRef=${userEntityRef}`;

    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async inviteUserToAtlassian(userEntityRef: string, products?: string[]) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/toolset/atlassian/user`;

    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'POST',
      body: JSON.stringify({
        userEntityRef: userEntityRef,
        products: products,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async getAtlassianUserGroups(userEntityRef: string, accountId: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/toolset/atlassian/group?accountId=${accountId}&userEntityRef=${userEntityRef}`;

    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async addUserToAtlassianGroup(
    userEntityRef: string,
    groupId: string,
    accountId: string,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/toolset/atlassian/group`;
    const payload = {
      groupId: groupId,
      accountId: accountId,
      userEntityRef: userEntityRef,
    };
    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async removeUserFromAtlassianGroup(
    userEntityRef: string,
    groupId: string,
    accountId: string,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/toolset/atlassian/group`;
    const payload = {
      groupId: groupId,
      accountId: accountId,
      userEntityRef: userEntityRef,
    };
    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async deleteUserFromAtlassian(userEntityRef: string, accountId: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/toolset/atlassian/user`;
    const payload = {
      accountId: accountId,
      userEntityRef: userEntityRef,
    };
    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async getBitbucketUser(userEntityRef: string, accountId: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/toolset/bitbucket/user?accountId=${accountId}&userEntityRef=${userEntityRef}`;

    const response = await this.fetchApi.fetch(requestUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async addUserToBitbucket(userEntityRef: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/toolset/bitbucket/user`;

    const body = {
      group_slug: 'bitbucket-users-dpo-electrolux',
      userEntityRef: userEntityRef,
    };

    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async deleteUserFromBitbucket(userEntityRef: string, uuid: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/toolset/bitbucket/user`;

    const body = {
      userEntityRef: userEntityRef,
      uuid: uuid,
    };

    const response = await this.fetchApi.fetch(requestUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }
}
