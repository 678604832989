import { createApiRef } from '@backstage/core-plugin-api';
import {
  TelemetryEventResponse,
  ApplianceIdsResponse,
  ApplianceInformationResponse,
  ApplianceStateResponse,
  UsersResponse,
  IotpLogsResponse,
} from './types';
import { ApplianceInformation } from '../components/ApplianceDisplayPanel';

/** @public */
export const EcpAppliancePageApiRef = createApiRef<EcpAppliancePageApi>({
  id: 'plugin.ecp-appliance-page.service',
});

/** @public */
export interface EcpAppliancePageApi {
  get(path: string): Promise<any>;
  getApplianceInfo(url: string): Promise<ApplianceInformation>;
  getApplianceIds(url: string): Promise<ApplianceIdsResponse>;
  getApplianceInfos(
    applianceIds: string[],
  ): Promise<ApplianceInformationResponse[]>;
  getApplianceState(applianceId: string): Promise<ApplianceStateResponse>;
  getUsers(applianceId: string): Promise<UsersResponse>;
  getIotpLogs(applianceId: string): Promise<IotpLogsResponse>;
  getTelemetryEvents(
    applianceId: string,
    startDateTime: string,
    endDateTime: string,
  ): Promise<TelemetryEventResponse>;
}
