export let REGION = 'emea';
export let ENV = 'staging';
export const PAGE = '/user-page';
export const PATH = 'api/v1/event/aggregation';
export let BASE_URL = `${PAGE}/${ENV}-${REGION}/${PATH}`;

export const modifyRegionAndEnv = (env: string, region: string) => {
  ENV = env;
  REGION = region;
  BASE_URL = `${PAGE}/${ENV}-${REGION}/${PATH}`;
};
