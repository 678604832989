import { createApiRef } from '@backstage/core-plugin-api';

import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { ExternalServices } from './types';

/** @public */
export const vpnApiRef = createApiRef<VpnApi>({
  id: 'plugin.vpn.service',
});

/** @public */
export class VpnClient implements VpnApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async getEnvironmentExternalServices(): Promise<
    Record<string, ExternalServices[]>
  > {
    return await this.get(`/vpn/external`);
  }

  async getVpnUser(user?: string): Promise<any> {
    if (user) {
      return await this.get(`/vpn/user/${user}`);
    }
    return await this.get(`/vpn/user`);
  }

  async get(path: string, format?: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/phoenix-idp-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }

    if (format && format === 'text') {
      return response.text();
    }
    return await response.json();
  }

  // DOWNLOAD
  async downloadVPNConfig(vpn_type?: string): Promise<any> {
    return await this.get(`/vpn/download_config?vpn_type=${vpn_type}`, 'text');
  }

  // POST
  async createConfig(payload: any, user?: string): Promise<any> {
    if (user) {
      return await this.post(`/vpn/user/${user}`, payload);
    }
    return await this.post(`/vpn/user`, payload);
  }

  async post(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/phoenix-idp-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'POST',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }

    return await response.json();
  }

  // PUT
  async renewConfig(user?: string): Promise<any> {
    if (user) {
      return await this.put(`/vpn/user/${user}`, {});
    }
    return await this.put(`/vpn/user`, {});
  }

  async put(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/phoenix-idp-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'PUT',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }

    return await response.json();
  }

  // DELETE
  async deleteConfig(payload: any, user?: string): Promise<any> {
    if (user) {
      return await this.delete(`/vpn/user/${user}`, payload);
    }
    return await this.delete(`/vpn/user`, payload);
  }

  async delete(path: string, payload: { [key: string]: any }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/phoenix-idp-backend${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      method: 'DELETE',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const payld = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payld}`;
      throw new Error(message);
    }

    return await response.json();
  }
}

/** @public */
export interface VpnApi {
  get(path: string, format?: string): Promise<any>;
  post(path: string, payload: { [key: string]: any }): Promise<any>;
  put(path: string, payload: { [key: string]: any }): Promise<any>;
  delete(path: string, payload: { [key: string]: any }): Promise<any>;
  downloadVPNConfig(vpn_type?: string): Promise<any>;
  getVpnUser(user?: string): Promise<any>;
  createConfig(payload: any, user?: string): Promise<any>;
  renewConfig(user?: string): Promise<any>;
  deleteConfig(payload: any, user?: string): Promise<any>;

  getEnvironmentExternalServices(): Promise<Record<string, ExternalServices[]>>;
}
