export enum Source {
  ARTIFACTORY = 'Artifactory',
  X_RAY = 'X-Ray',
}
export enum ScanType {
  SAST = 'SAST',
  SBOM = 'SBOM',
  FUNCTIONAL_TEST = 'Functional Test',
  CODE_COVERAGE = 'Code Coverage',
}

export enum BranchType {
  MASTER = 'Master',
  DEVELOP = 'Develop',
  FEATURE = 'Feature',
  RELEASE_CANDIDATE = 'Release Candidate',
}

export enum TestResultStatus {
  PASSED = 'Passed',
  FAILED = 'Failed',
  SKIPPED = 'Skipped',
  ABORTED = 'Aborted',
  BLOCKED = 'Blocked',
  TODO = 'Todo',
}
