import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { Audit } from '@internal/plugin-audits-common';

import { AuditApi } from './AuditApi';

/**
 * @public
 */
export class AuditClient implements AuditApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async getAllAudits(): Promise<Audit[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('audits');
    const resp = await this.fetchApi.fetch(`${baseUrl}`, {
      method: 'GET',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    return await resp.json();
  }

  async getAudit(id: string): Promise<Audit> {
    const baseUrl = await this.discoveryApi.getBaseUrl('audits');
    const resp = await this.fetchApi.fetch(`${baseUrl}/${id}`, {
      method: 'GET',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    return await resp.json();
  }

  async getEntityAudits(resource_id: string): Promise<Audit[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('audits');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/?resource_id=${resource_id}`,
      {
        method: 'GET',
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    return await resp.json();
  }
}
